import React, { useEffect, useContext, useState } from 'react';
import { Layout } from 'antd';
import { useRouter } from 'next/router';
import { UserContext } from '../contexts';
import PWAHead from './PWAHead';
import { Splash } from 'pwa/components';

const NonAuthenticatedLayout = ({ children }) => {
  const { user, isFetchingUser } = useContext(UserContext);
  const router = useRouter();
  const { Content } = Layout;
  // Don't use this way. Reference purposes
  // useEffect(() => {
  //   if (user && !isFetchingUser) {
  //     router.push('/app');
  //   }
  // }, [user, isFetchingUser]);

  if (isFetchingUser) {
    return (
      <Splash />
    )
  }

  return (
    <Content>
      <PWAHead />
      {children}
    </Content>
  );
};

export default NonAuthenticatedLayout;
