import React from 'react';
import { Progress, Tooltip } from 'antd';
import styles from '../styles/ProgressCircle.module.scss';

const calculatePercentage = (amountPassed, total) => {
  let result = 0;
  if (total !== 0 && amountPassed !== 0) {
    if (total && amountPassed) {
      result = (amountPassed / total) * 100;
    }
    return result.toFixed(0);
  } else {
    return result;
  }
};

export function ProgressCircleWithField({
  amountPassed,
  total,
  title,
  color,
  isEmptyTile,
  // onClick,
}) {
  const percentResult = calculatePercentage(amountPassed, total);
  return (
    <div className={styles.progressContent}>
      <Progress
        style={percentResult === 0 ? { borderRadius: '2px solid grey' } : {}}
        type="circle"
        percent={percentResult}
        strokeColor={color}
        strokeWidth={5}
        width={100}
        format={(percent) => {
          return (
            <div style={{ opacity: isEmptyTile ? 0 : 1 }}>
              <div className={styles.numberPercentage} style={{ color }}>
                {percent}
                <div className={styles.percentage}> %</div>
              </div>
            </div>
          );
        }}
      />
      <div className={styles.title}>{title}</div>
    </div>
  );
}

export function ProgressCircleWithTooltip({
  amountPassed,
  total,
  color,
  tooltipContent,
}) {
  const percentResult = calculatePercentage(amountPassed, total);
  return (
    <Tooltip
      title={tooltipContent}
      placement="bottomLeft"
      align={{ offset: [-20, -43] }}
      color="#0A0B09"
      arrowPointAtCenter
      overlayInnerStyle={{ borderRadius: '6px' }}
    >
      <Progress
        style={percentResult === 0 ? { borderRadius: '2px solid grey' } : {}}
        type="circle"
        percent={percentResult}
        strokeColor={color}
        strokeWidth={5}
        width={114}
        format={() => {
          return (
            <div className={styles.numberPercentage} style={{ color }}>
              {percentResult}
              <div className={styles.percentage}> %</div>
            </div>
          );
        }}
      />
    </Tooltip>
  );
}

export function ProgressCircleWithoutTooltip({ amountPassed, total, color }) {
  const percentResult = calculatePercentage(amountPassed, total);
  return (
    <Progress
      style={percentResult === 0 ? { borderRadius: '2px solid grey' } : {}}
      type="circle"
      percent={percentResult}
      strokeColor={color}
      strokeWidth={5}
      width={114}
      format={(percent) => {
        return (
          <div className={styles.numberPercentage} style={{ color }}>
            {percent}
            <div className={styles.percentage}> %</div>
          </div>
        );
      }}
    />
  );
}
