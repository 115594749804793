import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  TimePicker,
  Upload,
  notification,
} from 'antd';
import { getAsyncBase64 } from 'pwa/utils/getBase64';
import { useContext, useState } from 'react';
import { checkImageSpecification } from 'utils/biostarUtils';
import { getHourlyRate } from 'utils/objectGetters/workerGetters';
import { Icon } from '.';
import { CompanyContext } from '../../contexts';
import { getAntdSelectOptions } from '../../utils';
import { removeNonDigits, removeWhiteSpace } from '../../utils/general';
import styles from '../styles/Inputs.module.scss';
import { countryPhoneCode, getBase64 } from '../utils';

const { Option } = Select;
const { TextArea } = Input;

const INVALID_FILE_ERROR_MESSAGE =
  'Invalid image file. Please upload a file with a valid image extension.';
const INVALID_FILE_ERROR_MESSAGE_JPG_OR_PNG =
  'Invalid image file. Please select a JPG or PNG image file.';

function isFileImage(file) {
  const re = /image\/.*/;
  return re.test(file.type);
}

function isFileJpgOrPng(file) {
  const validMimeTypes = ['image/jpeg', 'image/png'];
  return validMimeTypes.includes(file.type);
}
export const InputField = ({
  placeholder,
  label,
  onChange,
  errors,
  suffix,
  fadeSuffix,
  suffixClick,
  ...rest
}) => {
  return (
    <div>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <Input
        className={styles.largePrimaryInput}
        placeholder={placeholder}
        onChange={onChange}
        suffix={
          <div onClick={suffixClick && suffixClick}>
            <Icon name={suffix} style={{ opacity: fadeSuffix ? 0.3 : 1 }} />
          </div>
        }
        {...rest}
      />
      {/* {errors && <span>Required</span>} */}
    </div>
  );
};

export const SelectField = (props) => {
  const {
    label,
    value,
    options,
    suffix,
    onChange,
    disabled,
    mode,
    tagRender,
    placeholder,
  } = props;
  return (
    <div>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <Select
        className={styles.largePrimarySelect}
        value={value}
        onChange={onChange}
        suffixIcon={<Icon name={suffix} size={18} />}
        mode={mode}
        disabled={disabled}
        tagRender={tagRender}
        placeholder={placeholder}
      >
        {options?.map((value, index) => (
          <Option className={styles.selectOptions} value={value}>
            {value}
          </Option>
        ))}
      </Select>
      {/* {errors && <span>Required</span>} */}
    </div>
  );
};

export const SelectField3 = (props) => {
  const {
    label,
    value,
    options,
    suffix,
    onChange,
    disabled,
    mode,
    tagRender,
    placeholder,
    ...rest
  } = props;
  return (
    <div>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <Select
        className={styles.largePrimarySelect}
        value={value}
        onChange={onChange}
        suffixIcon={<Icon name={suffix} size={18} />}
        mode={mode}
        disabled={disabled}
        tagRender={tagRender}
        placeholder={placeholder}
        {...rest}
      >
        {options?.map((option) => (
          <Option
            key={option.value}
            className={styles.selectOptions}
            value={option.value}
            label={option.label}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const SelectFieldCompany = (props) => {
  const { label, value, options, suffix, onChange, defaultValue } = props;

  return (
    <div>
      <div className={styles.inputLabel}>{label}</div>
      <Select
        className={styles.largePrimarySelectCompany}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        suffixIcon={<Icon name={suffix} size={18} />}
      >
        {options?.map((value, index) => (
          <Option className={styles.selectOptionsCompany} value={value}>
            {value}
          </Option>
        ))}
      </Select>
      {/* {errors && <span>Required</span>} */}
    </div>
  );
};

export const SelectField2 = (props) => {
  const { label, value, options, suffix, onChange, mode } = props;
  return (
    <div>
      <div className={styles.inputLabel}>{label}</div>
      <Select
        className={styles.largePrimarySelect}
        value={value}
        onChange={onChange}
        suffixIcon={<Icon name={suffix} size={18} />}
      >
        {value}
      </Select>
      {/* {errors && <span>Required</span>} */}
    </div>
  );
};

export const SelectFieldCheckbox = (props) => {
  const { label, value, options, suffix, onChange } = props;
  return (
    <div>
      <div className={styles.inputLabel}>{label}</div>
      <Select
        mode="multiple"
        className={styles.largePrimarySelect}
        value={value}
        onChange={onChange}
        suffixIcon={<Icon name={suffix} size={18} />}
      >
        {options?.map((value, index) => (
          <Option className={styles.selectOptions} value={value}>
            {value}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const SelectAgencies = (props) => {
  const { label, value, suffix, onChange, placeholder } = props;
  const { companyAgencies } = useContext(CompanyContext);

  return (
    <div>
      <div className={styles.inputLabel}>{label}</div>
      <Select
        mode="multiple"
        className={styles.largePrimarySelect}
        value={value}
        onChange={onChange}
        suffixIcon={<Icon name="icon-down-arrow" size={18} />}
        placeholder={placeholder}
      >
        {companyAgencies?.map((agency) => (
          <Option className={styles.selectOptions} value={agency.id}>
            {agency.agencyName}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const DateField = (props) => {
  const { label, onChange, ...rest } = props;
  return (
    <div>
      <div className={styles.inputLabel}>{label}</div>
      <DatePicker
        onChange={onChange}
        className={styles.datePicker}
        suffixIcon={<Icon name="icon-calendar" size={18} />}
        {...rest}
      />
    </div>
  );
};

export const InputNumberField = (props) => {
  const { label, placeholder, onChange, ...rest } = props;
  return (
    <div>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <InputNumber
        className={styles.largePrimaryInputNumber}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export const TimeField = (props) => {
  const { label, onChange, minuteStep, ...rest } = props;
  return (
    <div>
      <div className={styles.inputLabel}>{label}</div>
      <TimePicker
        className={styles.timePicker}
        format="HH:mm"
        minuteStep={minuteStep ?? 5}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export const PhotoField = (props) => {
  const { label, url, setUrl, setImage } = props;
  const handleChange = (e) => {
    getBase64(e.file.originFileObj, (imageUrl) => {
      setUrl(imageUrl);
      setImage(imageUrl);
    });
  };
  return (
    <div className={styles.photoField}>
      <div className={styles.imageTopContainer}>
        <div className={styles.inputLabel}>{label}</div>
        <Upload
          onChange={handleChange}
          showUploadList={false}
          listType="picture"
          accept="image/*,.mp4"
        >
          <div className={styles.uploadBtn}>
            <div style={{ marginRight: 5, fontWeight: 600 }}>Upload</div>
            <Icon name="icon-save" />
          </div>
        </Upload>
      </div>
      <div
        className={styles.imageContainer}
        style={{ backgroundImage: `url(${url})` }}
      />
    </div>
  );
};

export const FeaturePhotoField = (props) => {
  const { label, url, setUrl, setImage, disabled, setUnsavedChanges } = props;

  const [submissionError, setSubmissionError] = useState('');

  const handleChange = (e) => {
    if (!isFileJpgOrPng(e.file)) {
      setSubmissionError(INVALID_FILE_ERROR_MESSAGE_JPG_OR_PNG);
      return;
    }
    getBase64(e.file.originFileObj, (imageUrl) => {
      setUrl(imageUrl);
      setImage(imageUrl);
      setUnsavedChanges && setUnsavedChanges(true);
    });
    setSubmissionError('');
  };
  return (
    <div className={styles.photoField}>
      <div className={styles.imageTopContainer}>
        <div className={styles.inputLabel}>{label}</div>
        <Upload
          onChange={handleChange}
          showUploadList={false}
          listType="picture"
          accept="image/png, image/jpeg"
          disabled={disabled}
        >
          <div className={styles.uploadBtn}>
            <div style={{ marginRight: 5, fontWeight: 600 }}>Upload</div>
            <Icon name="icon-save" />
          </div>
        </Upload>
      </div>
      <div
        className={styles.featureImageContainer}
        style={{ backgroundImage: `url(${url})` }}
      />
      <div className={styles.formErrorMessage}>{submissionError}</div>
    </div>
  );
};

export const ProfilePhotoField = (props) => {
  const {
    label,
    url,
    setUrl,
    visible = true,
    setImage,
    checkImageSpecs,
    setNewImageUploaded,
    firebase,
  } = props;

  const [submissionError, setSubmissionError] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleImageCheck = async (imageUrl) => {
    try {
      const forBiostar = imageUrl.replace(/^data:image\/[a-z]+;base64,/, '');
      const res = await checkImageSpecification(forBiostar, firebase);
      return res;
    } catch (error) {
      console.error('Image specification check error:', error);
      return false;
    }
  };

  const handleChange = async (e) => {
    setUploading(true);
    try {
      if (!isFileImage(e.file)) {
        setSubmissionError(INVALID_FILE_ERROR_MESSAGE);
        setUploading(false);
        setNewImageUploaded(false);
        return;
      }
      // Putting an error message because we are not uploading directly
      // and antd will sent an error post failed as it will try to upload to server
      if (e.file.status === 'done' || e.file.status === 'error') {
        const imageUrl = await getAsyncBase64(e.file.originFileObj);
        if (checkImageSpecs) {
          const isValidImage = await handleImageCheck(imageUrl);
          if (!isValidImage) {
            setUrl('');
            setSubmissionError("Please upload a clearer photo of user's face.");
            setUploading(false);
            setNewImageUploaded(false);
            return;
          }
        }

        setNewImageUploaded(true);
        setUrl(imageUrl);
        setImage && setImage(e.file);
        setSubmissionError('');
        setUploading(false);
      }
    } catch (error) {
      console.error('Image handling error:', error);
      setSubmissionError('An error occurred while processing the image');
      setUrl('');
      setUploading(false);
    }
  };

  return (
    <div className={styles.photoField}>
      <div className={styles.imageTopContainer}>
        <div className={styles.inputLabel}>{label}</div>
        {visible && (
          <Upload
            onChange={handleChange}
            showUploadList={false}
            listType="picture"
            accept="image/*"
          >
            <div className={styles.uploadBtn}>
              {/* <Icon name="icon-save" /> */}
              {/* <Button
              icon={<UploadOutlined />}
              style={{
                borderColor: 'none',
                color: 'rgb(24,144,255)',
                marginTop: '-1rem',
              }}
            /> */}
              <UploadOutlined style={{ fontSize: '18px', marginTop: '1px' }} />
            </div>
          </Upload>
        )}
      </div>
      {!uploading && (
        <div
          className={styles.imageContainer}
          style={{ backgroundImage: `url(${url})` }}
        />
      )}
      {uploading && (
        <div
          className={`${styles.imageContainer} ${styles.imageContainer__loader}`}
        >
          <Space>
            <Spin size="large" />
          </Space>
        </div>
      )}
      <div className={styles.formErrorMessage}>{submissionError}</div>
    </div>
  );
};

export const TextAreaField = (props) => {
  const {
    placeholder,
    label,
    onChange,
    errors,
    errorMsg,
    rows,
    ...rest
  } = props;
  const numRows = rows || 5;
  return (
    <div>
      <div className={styles.inputLabel}>{label}</div>
      <TextArea
        className={styles.largeTextArea}
        placeholder={placeholder}
        onChange={onChange}
        rows={numRows}
        {...rest}
      />
      {errors && <span>{errorMsg}</span>}
    </div>
  );
};

export const MultiUpload = (props) => {
  const { label, fileList, handleUpdate, handleRemove } = props;

  const handleChange = ({ file }) => {
    if (file.status === 'uploading') {
      getBase64(file.originFileObj, (url) => {
        handleUpdate({
          url,
          name: file.name,
          type: file.type,
          uid: file.uid,
        });
      });
    }
  };

  const uploadButton = (
    <div style={{ fontSize: '33px' }}>
      <UploadOutlined />
    </div>
  );
  return (
    <div>
      {label && <div className={styles.inputLabel}>{label}</div>}
      <Upload
        listType="picture-card"
        // MultiUpload supports video and image files
        accept="video/*,image/*,.mkv"
        fileList={fileList}
        className={styles.uploadGallery}
        onChange={handleChange}
        onPreview={({ url, type }) => {
          let media;
          if (type.includes('image')) {
            media = new Image();
          } else {
            media = document.createElement('video');
            media.setAttribute('controls', 'controls');
          }
          media.src = url;
          const mediaWindow = window.open(url);
          mediaWindow.document.write(media.outerHTML);
        }}
        onRemove={(item) => {
          handleRemove(item.uid);
        }}
      >
        {uploadButton}
      </Upload>
    </div>
  );
};

export const MultiChoiceField = (props) => {
  const { prefix, ...rest } = props;
  return (
    <Input className={styles.largePrimaryInput} prefix={prefix} {...rest} />
  );
};

export const MobileInput = (props) => {
  const {
    countryCode,
    phoneValue,
    phonePlaceholder,
    phoneSuffix,
    fadeSuffix,
    countryOnChange,
    phoneOnChange,
    disabled,
    required,
  } = props;

  function handlePhoneOnChange(e) {
    e.target.value = removeNonDigits(e.target.value);
    phoneOnChange(e);
  }

  return (
    <div>
      <div className={styles.flextitle}>
        <div className={styles.inputLabel} style={{ width: 140 }}>
          Country
        </div>
        <div className={styles.inputLabel}>Phone Number</div>
      </div>
      <div className={styles.mobileInputContainer}>
        <Select
          className={styles.leftSide}
          value={countryCode}
          onChange={countryOnChange}
          dropdownMatchSelectWidth={400}
          placeholder="Code"
          showSearch
          optionLabelProp="label"
          disabled={disabled}
        >
          {countryPhoneCode.map((values) => (
            <Option
              value={values.dial_code}
              key={values.name}
              label={`${values.flag} ${values.dial_code}`}
            >
              <div className={styles.selectOptions}>
                <div style={{ flex: 5, overflow: 'hidden' }}>
                  {values.flag} {values.name}
                </div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                  {values.dial_code}
                </div>
              </div>
            </Option>
          ))}
        </Select>
        <Input
          disabled={disabled}
          value={phoneValue}
          className={styles.rightSide}
          type="tel"
          placeholder={phonePlaceholder}
          onChange={handlePhoneOnChange}
          required={required}
          suffix={
            <Icon
              name={phoneSuffix}
              style={{ opacity: fadeSuffix ? 0.3 : 1 }}
            />
          }
          // {...rest}
        />
        {/* {errors && <span>Required</span>} */}
      </div>
    </div>
  );
};

export const MobileInputAdmin = (props) => {
  const {
    countryCode,
    phoneValue,
    phonePlaceholder,
    phoneSuffix,
    fadeSuffix,
    countryOnChange,
    phoneOnChange,
    disabled,
    required,
  } = props;

  return (
    <div className={styles.mobileInputAdmin}>
      <div className={styles.flextitle}>
        <div className={styles.label} style={{ width: 140 }}>
          Country
        </div>
        <div className={styles.label}>Phone Number</div>
      </div>
      <div className={styles.mobileInputContainer}>
        <Select
          className={styles.leftSide}
          value={countryCode}
          onChange={countryOnChange}
          dropdownMatchSelectWidth={400}
          placeholder="Code"
          showSearch
          optionLabelProp="label"
          disabled={disabled}
        >
          {countryPhoneCode.map((values) => (
            <Option
              value={values.dial_code}
              key={values.name}
              label={`${values.flag} ${values.dial_code}`}
            >
              <div className={styles.selectOptions}>
                <div style={{ flex: 5, overflow: 'hidden' }}>
                  {values.flag} {values.name}
                </div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                  {values.dial_code}
                </div>
              </div>
            </Option>
          ))}
        </Select>
        <Input
          disabled={disabled}
          value={phoneValue}
          className={styles.rightSide}
          type="number"
          placeholder={phonePlaceholder}
          onChange={phoneOnChange}
          required={required}
          suffix={
            <Icon
              name={phoneSuffix}
              style={{ opacity: fadeSuffix ? 0.3 : 1 }}
            />
          }
        />
      </div>
    </div>
  );
};

function getRoleName(role, isHourlyRateCustom) {
  if (isHourlyRateCustom) {
    return 'Custom';
  }
  if (role?.name) {
    return role?.name;
  } else {
    return '';
  }
}

export const CustomEmailInput = ({
  email,
  isCreatingNewUser,
  setEmail,
  workerData,
  firebase,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const disabledInput = isCreatingNewUser ? false : !isEditing;

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const updateEmail = firebase.functions().httpsCallable('changeEmail');
      const resp = await updateEmail({
        email,
        id: workerData?.id,
        authenticationId: workerData?.authenticationId,
        isRegistered: workerData?.isRegistered,
      });
      setIsEditing(false);
      if (resp?.data?.status === 'success') {
        notification.success({
          message: resp?.data?.status ?? 'success',
          description: resp?.data?.message ?? 'Email updated successfully',
        });
      } else {
        notification.error({
          message: resp?.data?.status ?? 'failed',
          description: resp?.data?.message ?? 'Email update failed',
        });
      }
    } catch (error) {
      console.log('🚀 ~ handleUpdate ~ error:', error);
      notification.error({
        message: 'failed',
        description: 'Internal Server error please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEmail(workerData?.email);
    setIsEditing(false);
  };

  return (
    <div className={styles.custom__email_container}>
      <div className={styles.custom__email_action}>
        <Button
          type="link"
          className={styles.custom__email_action_blue}
          onClick={isEditing ? handleUpdate : () => setIsEditing(true)}
          disabled={isCreatingNewUser}
          loading={isLoading}
        >
          {isEditing ? 'Update' : `Edit`}
        </Button>
        {isEditing && (
          <Button
            type="link"
            className={styles.custom__email_action_grey}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}
      </div>
      <div>
        <InputField
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(removeWhiteSpace(e.target.value).toLowerCase());
          }}
          disabled={disabledInput}
        />
      </div>
    </div>
  );
};

export function HourlyRateInput({
  roleId,
  customHourlyRate,
  setCustomHourlyRate,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const { companyRoles } = useContext(CompanyContext);
  const selectedRole = companyRoles.find((role) => role.id === roleId);
  const result = getHourlyRate(selectedRole, customHourlyRate);

  function handleEdit() {
    setIsEditing(true);
  }

  function handleUpdate(newHourlyRate) {
    setIsEditing(false);
    setCustomHourlyRate(newHourlyRate);
  }

  function handleCancel() {
    setIsEditing(false);
  }

  return (
    <div className={styles.HourlyRateInput}>
      <div className={styles.hourlyRateLabel}>
        <div className={styles.inputLabel}>Hourly rate</div>
        <span className={styles.roleName}>
          {isEditing
            ? 'Custom'
            : getRoleName(selectedRole, result.isHourlyRateCustom)}
        </span>
      </div>
      {isEditing ? (
        <HourlyRateEdit
          customHourlyRate={customHourlyRate}
          isHourlyRateCustom={result.isHourlyRateCustom}
          onUpdate={handleUpdate}
          onCancel={handleCancel}
        />
      ) : (
        <HourlyRateDisplay value={result.hourlyRateValue} onEdit={handleEdit} />
      )}
    </div>
  );
}

function HourlyRateEdit({
  customHourlyRate,
  onUpdate,
  onCancel,
  isHourlyRateCustom,
}) {
  const defaultValue = isHourlyRateCustom ? customHourlyRate : null;
  const [editedHourlyRate, setEditedHourlyRate] = useState(defaultValue);
  return (
    <div className={styles.updateInput}>
      <InputField
        value={editedHourlyRate}
        onChange={(e) => setEditedHourlyRate(e.target.value)}
      />
      <div className={styles.updateButtons}>
        <Button
          type="link"
          className={styles.blueButton}
          onClick={() => onUpdate(editedHourlyRate)}
        >
          Update
        </Button>
        <Button type="link" className={styles.greyButton} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

function HourlyRateDisplay({ value, onEdit }) {
  const displayValue = value === 'Not specified' ? value : `£ ${value}`;
  return (
    <div className={styles.valueDisplay}>
      <div className={styles.hourlyRateValue}>{displayValue}</div>
      <Button type="link" className={styles.blueButton} onClick={onEdit}>
        Edit
      </Button>
    </div>
  );
}

export function DepartmentsSelect({
  departmentsIds = [],
  onChange,
  label = 'Departments',
}) {
  const { companyDepartments } = useContext(CompanyContext);

  const companyDepartmentsIds = [
    ...companyDepartments.map(({ id }) => id),
    'All',
  ];
  const existingDepartmentsIds = departmentsIds.filter((departmentId) =>
    companyDepartmentsIds.includes(departmentId),
  );

  return (
    <SelectWithAll
      options={getAntdSelectOptions(companyDepartments)}
      onChange={onChange}
      value={existingDepartmentsIds}
      label={label}
    />
  );
}

export function RolesSelect({ rolesIds = [], onChange, label = 'Roles' }) {
  const { companyRoles } = useContext(CompanyContext);

  const companyRolesIds = [...companyRoles.map(({ id }) => id), 'All'];
  const existingRoleIds = rolesIds.filter((roleId) =>
    companyRolesIds.includes(roleId),
  );
  return (
    <SelectWithAll
      options={getAntdSelectOptions(companyRoles)}
      onChange={onChange}
      value={existingRoleIds}
      label={label}
    />
  );
}

function SelectWithAll({ options, onChange, value, label }) {
  // Adding 'All' option to the list of options
  const optionsWithAll = [
    ...options,
    {
      label: 'All',
      value: 'All',
    },
  ];

  function handleChange(selectedIds) {
    const clickedAll = selectedIds.at(-1) === 'All';
    const removedLastBox = !selectedIds.length;

    if (clickedAll || removedLastBox) {
      onChange(['All']);
    } else {
      const withoutAll = selectedIds.filter((id) => id !== 'All');
      onChange(withoutAll);
    }
  }

  return (
    <SelectField3
      options={optionsWithAll}
      onChange={handleChange}
      label={label}
      value={value}
      suffix="icon-down-arrow"
      mode="multiple"
    />
  );
}
