/**
 * Divides the file.name into an array containing name and type of the file
 *
 * ex. "document.txt" becomes ["document", "txt"]
 * @param {String} fileName
 * @returns {Array<String>}
 */
function divideNameAndType(fileName) {
  return fileName.split(/\.(?=[^.]*$)/);
}

export { divideNameAndType };
