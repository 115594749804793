import moment from 'moment-timezone';
import jwt from 'jsonwebtoken';
import { INVITATION_SECRET } from 'admin/config';

export const truncate = (str, n) => {
  return str !== null && str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

/**
 * This function groups users based on their status for venue statistics
 * @param {[]} staffArray
 * @param {String} companyId
 * @returns
 */
export const groupStaffByStatus = (staffArray, companyId) => {
  return staffArray.reduce((grouped, staff) => {
    const status = staff.statuses?.[companyId]?.statusId;
    if (status !== undefined) {
      grouped[status] = grouped[status] || [];
      grouped[status].push(staff);
    }
    return grouped;
  }, {});
};

/**
 * This function checks if the password is of the correct format
 * @param {String} password
 * @returns
 */
export const validatePassword = (password) => {
  const atLeastOneUppercase = /(?=.*[A-Z])/;
  const atLeastOneLowercase = /(?=.*[a-z])/;
  const atLeastOneDigit = /(?=.*\d)/;
  const atLeastTenCharacters = /.{10,}/;
  // not a character, not a digit, not a whitespace
  const atLeastOneSpecial = /(?=.*[^\w\d\s])/;
  return (
    atLeastOneUppercase.test(password) &&
    atLeastOneLowercase.test(password) &&
    atLeastOneDigit.test(password) &&
    atLeastTenCharacters.test(password) &&
    atLeastOneSpecial.test(password)
  );
};

/**
 * This function formats the time to Europe/London timezone
 * @param {DateTime} date
 * @returns `Time` in `HH:mm` format
 */
export const formatTimeForLondonTimeZone = (date) => {
  const parsedDate = moment(date).tz('Europe/London');
  return parsedDate.format('HH:mm');
};

/**
 * This function takes an email and returns JWT token
 * @param {String} email
 * @returns {String} token
 */
export const getJwtToken = async (email) => {
  const token = jwt.sign({ email }, INVITATION_SECRET);
  return token;
};
