import React, { useContext, useState, useEffect } from 'react';
import { Layout, Popover, Select, Menu } from 'antd';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { AdminUserContext } from '../../contexts';
import Avatar from './Avatar';
import styles from '../styles/Header.module.scss';
import { FirebaseContext } from '../../contexts/FirebaseContext';
import ProfileDrawer from './ProfileDrawer';
import { isAuthorized } from '../utils/adminRoleManager';

const { Header: AntHeader } = Layout;
const { Option } = Select;

const Header = () => {
  const { state, user, company, setSwitchCompany } = useContext(
    AdminUserContext,
  );
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const router = useRouter();
  const [showProfileDrawer, setShowProfileDrawer] = useState(false);
  const [companies, setCompanies] = useState(null);

  const auth = useContext(FirebaseContext).auth();

  useEffect(() => {
    async function fetchData() {
      const companiesData = {};
      const companiesSnapshot = await db
        .collection('companies')
        .where('id', 'in', user?.memberOfCompanyId)
        .get();

      companiesSnapshot.docs.forEach(async (companyDocs) => {
        companiesData[companyDocs.id] = companyDocs.data();
      });
      setCompanies(companiesData);
    }
    if (user?.memberOfCompanyId?.length > 0) {
      fetchData();
    }
  }, []);

  const onSwitchCompany = (data) => {
    setSwitchCompany(data);
  };

  const closeDrawer = () => {
    setShowProfileDrawer(false);
  };
  return (
    <div>
      <AntHeader className={styles.headerContainer}>
        <div className={styles.headerLogo}>
          {state?.headerBackBtn ? (
            <div className={styles.backBtn} onClick={() => router.back()}>
              <LeftOutlined style={{ color: '#FFFFFF', fontSize: 24 }} />
            </div>
          ) : (
            <div className={styles.logoContainer}>
              {/* <Image
                className={styles.logo}
                src="/images/aposto-icon-yellow.png"
                alt="Header Logo"
                width={46}
                height={46}
              /> */}
              <Image
                className={styles.logo}
                src={company?.urlSmallLogo ?? '/images/aposto-icon-yellow.png'}
                alt="Header Logo"
                width={46}
                height={46}
              />
            </div>
          )}
        </div>
        <div className={styles.headerContent}>
          <div>{state?.headerTitle}</div>
          <div className={styles.headerRight}>
            <div
              className={styles.logoutBtn}
              onClick={async () => {
                await db
                  .collection('admin-users')
                  .doc(user?.id)
                  // .set({ is2FAAuthenticated: false }, { merge: true });
                auth.signOut();
                // router.push('/admin/login');
              }}
            >
              Logout
            </div>

            <Popover
              placement="bottomRight"
              content={() => (
                <div className={styles.popverContainer}>
                  <div
                    className={styles.popoverbutton}
                    onClick={() => setShowProfileDrawer(true)}
                  >
                    Account Settings
                  </div>
                  {isAuthorized(user.role, 'companySettings') && (
                    <div
                      className={styles.popoverbutton}
                      onClick={() => router.push('/admin/company-settings')}
                    >
                      Company Settings
                    </div>
                  )}

                  {isAuthorized(user.role, 'switchCompany') && (
                    <div
                      className={styles.popoverbutton}
                      onClick={() => {
                        // console.log('user', companies);
                      }}
                    >
                      Switch Company
                      <Select
                        value={user.companyId}
                        style={{ width: '100%' }}
                        onChange={onSwitchCompany}
                      >
                        {user?.memberOfCompanyId?.map((i) => (
                          <Option value={i}>
                            {companies && companies[i]?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              )}
            >
              <div style={{ height: 40 }}>
                {user.profilePhoto ? (
                  <Avatar shape="square" src={user.profilePhoto} />
                ) : (
                  <div style={{ paddingTop: 12 }}>
                    <div className={styles.emptyAvatar}>
                      <div style={{ paddingTop: 10 }}>
                        <UserOutlined style={{ fontSize: 30 }} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Popover>
          </div>
        </div>
      </AntHeader>

      <ProfileDrawer
        showProfileDrawer={showProfileDrawer}
        profileData={user}
        closeDrawer={() => closeDrawer()}
      />
    </div>
  );
};

export default Header;