import moment from 'moment';
import { useRouter } from 'next/router';
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { ROLES } from '../admin/constants';
import { initialState, reducer } from './admin-reducer';
import { FirebaseContext } from './FirebaseContext';
import useLocalStorage from './useLocalStorage';

export const AdminUserContext = createContext(null);

const AdminUserProvider = (props) => {
  const { children } = props;
  const router = useRouter();
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [switchCompany, setSwitchCompany] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [companyPackage, setCompanyPackage] = useState(0);
  const [verified, setVerified] = useLocalStorage('verified', false);
  const [isAuth, setIsAuth] = useState(false);

  const companyPackageDeterminer = () => {
    switch (company.package) {
      case 'Briefing + Training':
        return 0;

      case 'Briefing + Training + Scheduling':
        return 1;

      case 'Briefing + Training + Scheduling + Biostar Integration':
        return 2;

      default:
        throw Error('Unknown company package');
    }
  };

  useEffect(() => check2Fa(), [router.route, user]);

  // If user chose dyslexia font in his account settings
  useEffect(() => {
    if (user?.hasDyslexia) {
      document.body.classList.add('dyslexieFont');
    } else {
      document.body.classList.remove('dyslexieFont');
    }

    return () => {
      document.body.classList.remove('dyslexieFont');
    };
  }, [user?.hasDyslexia]);

  const check2Fa = () => {
    if (
      user?.is2FAAuthenticated &&
      !verified &&
      !router.route.includes('/login')
    ) {
      router.push('/admin/login');
    } else if (user?.role === ROLES.apostoAdmin && router.route === '/admin') {
      router.push('/admin/management');
    }
  };

  useEffect(() => {
    if (!isFetchingUser) {
      if (user?.role === 'apostoAdmin') {
        setIsAuth(true);
      } else if (!user?.is2FAAuthenticated) {
        setIsAuth(true);
      } else if (user && verified) {
        setIsAuth(true);
      } else if (!firebase.auth().currentUser) {
        setIsAuth(false);
      }
    }
  }, [user, verified, isFetchingUser]);

  useEffect(() => {
    if (company?.package) {
      const membershipPackage = companyPackageDeterminer();
      setCompanyPackage(membershipPackage);
    }
  }, [company]);

  async function getWorkerUserData(userAuth) {
    const userSnapshot = await db
      .collection('users')
      .where('authenticationId', '==', userAuth.uid)
      .get();

    let userData = userSnapshot.docs[0]?.data();

    if (!userData) {
      const userSnapshot2 = await db
        .collection('users')
        .where('email', '==', userAuth.email)
        .get();
      userData = userSnapshot2.docs[0]?.data();
    }
    return userData;
  }

  async function getAdminUserData(userAuth) {
    const userSnapshot = await db
      .collection('admin-users')
      .doc(userAuth?.uid)
      .get();

    let userData = userSnapshot.data();

    if (!userData) {
      const userSnapshot2 = await db
        .collection('admin-users')
        .where('email', '==', userAuth?.email)
        .get();
      userData = userSnapshot2.docs[0]?.data();
    }
    return userData;
  }

  async function assignCompanyIdIfNotAssigned(userData) {
    // AgentRepresentatives and eventManagers might not have companyId assigned
    // TODO: make sure they have companyId assigned instead of checking all the time
    // and remove this function later as it is not necessary for login
    if (
      !userData.companyId &&
      userData.role === ROLES.eventManager &&
      userData.role === ROLES.agentRepresentative
    ) {
      let tempCompany = userData?.memberOfCompanyId[0];
      await db
        .collection('admin-users')
        .doc(userData.id)
        .update({ companyId: tempCompany });
      setUser((prevUserData) => ({ ...prevUserData, companyId: tempCompany }));
    }
  }

  function handleLogOut() {
    // Clear all items except inactivityLogout
    const inactivityLogout = localStorage.getItem('inactivityLogout');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('inactivityLogout', inactivityLogout);
    setVerified(false);
    // User set to null will redirect to login if somebody tries to access a protected route
    setUser(null);
  }

  async function handleAuthStateChanged(userAuth) {
    const hasUserLoggedOut = !userAuth;
    if (hasUserLoggedOut) {
      handleLogOut();
      return;
    }

    // Don't show the Event Manager Portal to Sterwards
    const isUserWorker = Boolean(await getWorkerUserData(userAuth));
    if (isUserWorker) {
      // Show information on the login page
      // that you were logged out because you have logged in as worker
      dispatch({ type: 'workerLoggedIn', value: true });
      handleLogOut();
      return;
    }

    const adminUser = await getAdminUserData(userAuth);
    const isUserAdmin = Boolean(adminUser);
    if (isUserAdmin) {
      dispatch({ type: 'adminLoggedIn', value: true });
      setUser(adminUser);
      await assignCompanyIdIfNotAssigned(adminUser);
    } else {
      // User not found in the database
      console.log('User not found in the database');
      handleLogOut();
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (userAuth) => {
      setIsFetchingUser(true);
      await handleAuthStateChanged(userAuth);
      setIsFetchingUser(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user?.companyId) {
      const unsubscribeCompanies = db
        .collection('companies')
        .doc(user?.companyId)
        .onSnapshot(async (querySnapshot) => {
          let companyData = querySnapshot.data();
          setCompany(companyData);
        });

      return () => {
        unsubscribeCompanies();
      };
    }
  }, [user]);

  useEffect(async () => {
    if (switchCompany) {
      await db
        .collection('admin-users')
        .doc(user.id)
        .update({ companyId: switchCompany });
      router.reload();
    }
  }, [switchCompany]);

  useEffect(() => {
    if (!user) {
      return () => {};
    }
    function resetTimer() {
      localStorage.setItem('lastActivity', dayjs());
    }
    // resetTimer on login
    resetTimer();
    // resetTimer on every click
    document.addEventListener('click', resetTimer);
    return () => {
      document.removeEventListener('click', resetTimer);
    };
  }, [user]);

  useEffect(() => {
    if (!user) {
      return () => {};
    }
    const interval = setInterval(async () => {
      const lastActvity = localStorage.getItem('lastActivity');
      const dayjsLastActivity = dayjs(lastActvity);
      if (!lastActvity) {
        return;
      }
      const currentDate = dayjs();
      const inactivityTime = currentDate.diff(dayjsLastActivity, 'hours');
      if (inactivityTime > 2) {
        localStorage.setItem('inactivityLogout', '1');
        await firebase.auth().signOut();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [user]);

  const refreshUser = async () => {
    const userSnapshot = await db.collection('admin-users').doc(user.id).get();
    const userData = userSnapshot.data();

    if (userData) {
      setUser({ ...userData });
    }
  };

  return (
    <AdminUserContext.Provider
      value={{
        user,
        isFetchingUser,
        state,
        dispatch,
        refreshUser,
        company,
        companyPackage,
        setSwitchCompany,
        verified,
        setVerified,
        isAuth,
        setIsAuth,
      }}
    >
      {children}
    </AdminUserContext.Provider>
  );
};

export default AdminUserProvider;
