export default [
  {
    path: '/app/login',
    private: false,
  },
  {
    path: '/app/registration',
    private: false,
  },
  {
    path: '/app/forgot-password',
    private: false,
  },
  {
    path: '/app/company-invitation',
    private: false,
  },
];
