import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Row, Col } from 'antd';
import dayjs from 'dayjs';
import Icon from './Icon';
import styles from '../styles/EventCard.module.scss';

const EventCard = ({
  id,
  name,
  date,
  time,
  required,
  recruited,
  location,
  url,
  editMode,
  isEmpty,
}) => {
  if (isEmpty) {
    return (
      <div style={{ marginBottom: 20 }}>
        <div className={styles.eventCard}>
          <div className={styles.emptyCardText}>
            There are no upcoming events scheduled.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ marginBottom: 20 }}>
      <Row className={styles.eventCard}>
        <Col span={16} className={styles.contentLeft}>
          <div className={styles.matchContainer}>
            <div className={styles.matchTimeStamp}>
              {dayjs.unix(date.seconds).format('ddd, D MMM, YYYY')} • {time}
            </div>
            <div className={styles.matchDetails}>
              <div className={styles.matchIcon}>
                <Icon
                  name="icon-football"
                  className="event-card-icon"
                  size={24}
                />
              </div>
              <div>
                <div className={styles.matchName}>{name}</div>
                <div className={styles.matchLocation}>
                  at {location.address}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.imageContainer}>
            {url && <img src={url} />}
          </div>
        </Col>
        <Col span={8} className={styles.contentRight}>
          <div className={styles.recruitContainer}>
            <div className={styles.staffRecruit}>
              <span className={styles.recruitTitle}>Required</span>
              <span>{required}</span>
            </div>
            <div className={styles.staffRecruit}>
              <span className={styles.recruitTitle}>Recruited</span>
              <span>{recruited}</span>
            </div>
          </div>
          <div className={styles.editBtn}>
            <Link href={{ pathname: `/admin/event-new-edit/`, query: { id } }}>
              {editMode ? (
                <a>View/Edit Event</a>
              ) : (
                <a>View/Edit Event Details</a>
              )}
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EventCard;
