import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import PlacesAutoComplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Icon from './Icon';
import styles from '../styles/SearchLocation.module.scss';

const SearchLocation = (props) => {
  const { label, onChange, defaultValue, setGeoloc, disabled } = props;
  const [address, setAddress] = useState('');

  useEffect(() => {
    setAddress(defaultValue?.address);
  }, [defaultValue]);

  const handleChange = (value) => {
    setAddress(value);
  };

  const handleSelect = (value) => {
    setAddress(value);
    onChange(value);
    geocodeByAddress(value)
      .then(async (results) => {
        const geoloc = await getLatLng(results[0]);
        setGeoloc(geoloc);
        const countryExist = results[0].address_components.find((i) =>
          i.types.includes('country'),
        );
        const cityExist = results[0].address_components.find((i) =>
          i.types.includes('locality'),
        );
        const routeExist = results[0].address_components.find((i) =>
          i.types.includes('route'),
        );
        const postcodeExist = results[0].address_components.find((i) =>
          i.types.includes('postal_code'),
        );
        const country = countryExist ? countryExist.long_name : null;
        const city = cityExist ? cityExist.long_name : null;
        const route = routeExist ? routeExist.long_name : null;
        const postcode = postcodeExist
          ? parseInt(postcodeExist.long_name, 10)
          : null;

        return {
          address: value,
          place: value.split(',')[0],
          street: route,
          city,
          postcode,
          country,
          coordinates: geoloc,
        };
      })
      .then((addressObj) => {
        onChange(addressObj);
      })
      .catch((error) => console.error('Error', error));
  };
  return (
    <PlacesAutoComplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="input-container" style={{ position: 'relative' }}>
          <div>
            <div className={styles.inputLabel}>{label}</div>
            <Input
              {...getInputProps({
                allowClear: true,
                className: styles.searchInput,
                suffix: <Icon name="icon-place" size={18} />,
              })}
              disabled={disabled}
            />
            <div className={styles.autoCompleteDropdown}>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? styles.suggestionItemActive
                  : styles.suggestionItem;
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutoComplete>
  );
};

export default SearchLocation;