// Functions to get nested properties of worker
// if there are some changes it is easier to change it in one place

import { arrayToIndexedObject, populateId } from '../general';

export function getWorkerDepartmentsIds(worker, companyId) {
  return worker?.departments?.[companyId] ?? [];
}

export function getWorkerRoleId(worker, companyId) {
  return worker?.roles?.[companyId]?.roleId ?? null;
}

export function getWorkerSectionId(staff, eventId) {
  return staff?.sections?.[eventId]?.sectionId ?? null;
}

export function getWorkerStatusId(worker, companyId) {
  return worker.statuses?.[companyId]?.statusId ?? null;
}

// More complex ones:

export function getWorkerRole(worker, companyRoles, companyId) {
  const roleId = getWorkerRoleId(worker, companyId);
  const role = populateId(roleId, companyRoles);
  return role;
}

export function getWorkerSection(worker, companySections, eventId) {
  const sectionId = getWorkerSectionId(worker, eventId);
  const section = populateId(sectionId, companySections);
  return section;
}

export function getWorkerStatus(worker, companyStatuses, companyId) {
  const statusId = getWorkerStatusId(worker, companyId);
  const status = populateId(statusId, companyStatuses);
  return status;
}

export function getWorkerAgencyIds(worker, companyId) {
  return worker.agency?.companyId?.[companyId] ?? [];
}

export function getWorkerAgencies(worker, staffingAgencies, companyId) {
  const workerAgenciesIds = getWorkerAgencyIds(worker, companyId);
  if (!workerAgenciesIds) return '';
  const workerAgenciesById = arrayToIndexedObject(staffingAgencies, 'id');
  const workerAgencies = workerAgenciesIds
    .map((id) => workerAgenciesById[id])
    .filter(Boolean);
  return workerAgencies;
}

export function getCustomHourlyRate(worker, companyId) {
  return worker?.customHourlyRate?.[companyId] ?? '';
}

export function getHourlyRate(selectedRole, customHourlyRate) {
  const selectedRoleHourlyRate = selectedRole?.hourlyRate;

  const isHourlyRateCustom =
    customHourlyRate && customHourlyRate !== selectedRoleHourlyRate;

  let hourlyRateValue;
  if (isHourlyRateCustom) {
    hourlyRateValue = `${customHourlyRate}`;
  } else if (selectedRoleHourlyRate) {
    hourlyRateValue = `${selectedRoleHourlyRate}`;
  } else {
    hourlyRateValue = 'Not specified';
  }

  return { hourlyRateValue, isHourlyRateCustom };
}
