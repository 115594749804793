export const ROLES = {
  apostoAdmin: 'apostoAdmin',
  companyAdmin: 'companyAdmin',
  eventManager: 'eventManager',
  agentRepresentative: 'agentRepresentative',
};

export const ROLES_LABEL = {
  apostoAdmin: 'Aposto Admin',
  companyAdmin: 'Company Admin',
  eventManager: 'Event Manager',
  agentRepresentative: 'Agency Representative',
};
