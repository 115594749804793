import React, { useState, useEffect, useContext } from 'react';
import { RightOutlined } from '@ant-design/icons';
import Icon from './Icon';
import styles from '../styles/components/TrainingBox.module.scss';
import { SHARED_CONFIG } from '../../config/sharedConfig';
import { FirebaseContext } from '../../contexts';

const TrainingBox = ({
  name,
  topicId,
  tookQuiz,
  quizId,
  readProgress,
  topicSize,
  grade,
  onClick,
  onClickBot,
}) => {
  const [quizPassingGrade, setQuizPassingGrade] = useState();
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();

  useEffect(async () => {
    const quizSnapshot = await db.collection('quiz').doc(quizId).get();
    setQuizPassingGrade(
      quizSnapshot?.data()?.passRate ?? SHARED_CONFIG.passingGrade,
    );
  }, [quizId]);

  const getPercentageString = (returnSymbol) => {
    if (returnSymbol) {
      // return `${(currentCompleted / total) * 100}%`;
      return `${grade}%`;
    }

    // return `${(currentCompleted / total) * 100}`;
    return `${grade}`;
  };

  const getPercentage = () => {
    // return (currentCompleted / total) * 100;
    return grade;
  };

  const getPercentageColor = () => {
    if (getPercentage() < quizPassingGrade) {
      return '#FB4953';
    }
    // if (getPercentage() >= 40 && getPercentage() < 69) {
    //   return '#FAAD14';
    // }
    if (getPercentage() >= quizPassingGrade) {
      return '#52C41A';
    }

    return null;
  };

  const getPercentageStatus = () => {
    if (getPercentage() < quizPassingGrade) {
      return 'TRY AGAIN';
    }
    if (getPercentage() >= quizPassingGrade) {
      return 'PASSED';
    }

    return null;
  };

  return (
    <div className={`${styles.container} ${styles.shadow}`}>
      <div
        className={styles.topContainer}
        onClick={() => {
          onClick({ topicId });
        }}
      >
        <div>
          <div className={styles.titleText}>{name}</div>
          <div className={styles.progressText}>
            read progress • {readProgress} of {topicSize && topicSize}
          </div>
        </div>
        <RightOutlined className={styles.rightIconWhite} />
      </div>
      {/* quizId is to check if quiz exists for the module otherwise hide bottom part */}
      {quizId && (
        <div>
          {tookQuiz === false && (
            <div
              className={styles.bottomContainer}
              onClick={() => {
                onClickBot({ topicId });
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon
                    name="icon-qa"
                    size={26}
                    className={styles.announcementIcon}
                  />
                  <div className={styles.takeQuizText}>
                    Take the assessment!
                  </div>
                </div>
                <RightOutlined className={styles.rightIcon} />
              </div>
            </div>
          )}
          {tookQuiz === true && (
            <div
              className={styles.bottomContainer}
              onClick={() => onClickBot({ topicId })}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className={styles.statusText}>{getPercentageStatus()}</div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '5px',
                  }}
                >
                  <div className={styles.scoreText}>SCORE</div>
                  <div className={styles.percentageText}>
                    {getPercentageString()}
                  </div>
                  <div className={styles.percentageSymbol}>%</div>
                  <RightOutlined className={styles.rightIcon} />
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <div className={styles.greyPercentageBar} />
                <div
                  className={styles.percentageBar}
                  style={{
                    width: getPercentageString(true),
                    backgroundColor: getPercentageColor(),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TrainingBox;
