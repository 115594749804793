const Icon = (props) => {
  return (
    <i
      className={`${props.name} ${props.className}`}
      style={{ fontSize: props.size, ...props.style }}
      onClick={props.onClick}
    />
  );
};

export default Icon;
