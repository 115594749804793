import {
  LogoutOutlined,
  ShopOutlined,
  UnlockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Menu, notification, Popover, Row } from 'antd';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { AdminUserContext, FirebaseContext } from '../../contexts';
import { InputField } from './Inputs';
import { validatePassword } from 'admin/utils/misc';

const ApostoAdminHeader = () => {
  const [current, setCurrent] = useState();
  const router = useRouter();
  const firebase = useContext(FirebaseContext);
  const { user } = useContext(AdminUserContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [submissionError, setSubmissionError] = useState();
  const [isRPVisible, setIsRPVisible] = useState();

  useEffect(() => {
    setCurrent(router.pathname);
  }, []);

  const handleClick = (e) => {
    if (e.key === 'logout') {
      firebase.auth().signOut();
    } else if (e.key === 'reset') {
      // do nothing
    } else {
      setCurrent(e.key);
      router.push(e.key);
    }
  };

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setCurrentPassword('');
      setNewPassword1('');
      setNewPassword2('');
      setSubmissionError();
    }
    setIsRPVisible(visible);
  };

  const handleSubmitPassword = async () => {
    if (!currentPassword || !newPassword1 || !newPassword2) {
      setSubmissionError('Please fill in all fields');
    } else if (!validatePassword(newPassword1)) {
      setSubmissionError(
        // eslint-disable-next-line max-len
        'Please fill in at least 10 characters, 1 capital letter, 1 lowercase letter, 1 number and 1 special character',
      );
    } else if (newPassword1 === newPassword2) {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(user.email, currentPassword);
        await firebase.auth().currentUser.updatePassword(newPassword1);
        setCurrentPassword('');
        setNewPassword1('');
        setNewPassword2('');
        setIsRPVisible(false);
        notification.open({
          message: 'Success',
          description: 'Successfully changed password!',
        });
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          setSubmissionError('The current password is invalid');
        } else {
          setSubmissionError(error.message);
        }
      }
    } else {
      setSubmissionError('Passwords do not match');
    }
  };

  const content = (
    <Row style={{ maxWidth: 300 }}>
      <Col span={24}>
        <Form.Item>
          <InputField
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            label="Current Password"
            required
            type="password"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <InputField
            value={newPassword1}
            onChange={(e) => setNewPassword1(e.target.value)}
            label="New Password"
            required
            type="password"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <InputField
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
            label="Confirm New Password"
            required
            type="password"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Button
          onClick={handleSubmitPassword}
          type="primary"
          style={{
            width: '100%',
            borderRadius: 6,
            height: 50,
            fontSize: 16,
          }}
        >
          Change Password
        </Button>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'red',
            padding: 10,
          }}
        >
          {submissionError}
        </div>
      </Col>
    </Row>
  );

  return (
    <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
      <Menu.Item key="/admin/management" icon={<UserOutlined />}>
        Users
      </Menu.Item>
      <Menu.Item key="/admin/management/companies" icon={<ShopOutlined />}>
        Companies
      </Menu.Item>
      <Menu.Item
        style={{ float: 'right' }}
        key="logout"
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>

      <Menu.Item
        style={{ float: 'right' }}
        key="reset"
        icon={<UnlockOutlined />}
      >
        <Popover
          content={content}
          trigger="click"
          onVisibleChange={handleVisibleChange}
          visible={isRPVisible}
        >
          Reset Password
        </Popover>
      </Menu.Item>
    </Menu>
  );
};

export default ApostoAdminHeader;