import React, { useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { Layout } from 'antd';
import { AdminUserContext } from '../contexts';
import { ROLES } from '../admin/constants';

const AdminAuthLayout = ({ children }) => {
  const router = useRouter();
  const { user, isFetchingUser } = useContext(AdminUserContext);
  const { Content } = Layout;

  // if (user && !isFetchingUser) {
  //   router.push('/admin');
  // }

  return <Content>{children}</Content>;
};

export default AdminAuthLayout;
