import React, { useState, useEffect, useRef, useContext } from 'react';
import CustomUploadAdapter from './CustomUploadAdapter';
import { FirebaseContext } from '../../../contexts';
import { Loading } from 'admin/components';

export default function RichTextEditor(props) {
  const { docId, htmlData, disabled, onChange, simple, storageName } = props;
  const editorRef = useRef();
  const firebase = useContext(FirebaseContext);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, CustomEditor } = editorRef.current || {};
  const [editorData, setEditorData] = useState();

  const editorConfiguration = {
    toolbar: {
      items: simple
        ? [
            'undo',
            'redo',
            'bold',
            'italic',
            'underline',
            'styles',
            'colors',
            'link',
            '|',
            'numberedList',
            'bulletedList',
          ]
        : [
            'undo',
            'redo',
            'heading',
            'fontSize',
            'bold',
            'italic',
            'underline',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'link',
            'imageUpload',
            'insertTable',
            'mediaEmbed',
          ],
      shouldNotGroupWhenFull: true,
    },
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
      upload: {
        types: ['jpeg', 'png'],
      },
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    placeholderConfig: {
      types: ['Name', 'DOB'],
    },
    mediaEmbed: {
      previewsInData: true,
    },
    extraPlugins: [MyCustomUploadAdapterPlugin],
    contentsLangDirection: 'ltr',
    language: 'en',
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react'),
      CustomEditor: require('./build/ckeditor'),
    };
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    setEditorData(htmlData);
  }, [htmlData]);

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader, firebase, docId, storageName);
    };
  }

  return editorLoaded ? (
    <CKEditor.CKEditor
      editor={CustomEditor}
      config={editorConfiguration}
      data={editorData}
      disabled={disabled}
      onReady={(editor) => {
        // editor.plugins.get('FileRepository').createUploadAdapter = function (
        //   loader,
        // ) {
        //   return new CustomUploadAdapter(loader);
        // };
        // You can store the "editor" and use when it is needed.
        console.log('Editor is ready to use!', editor);
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        setEditorData(data);
        onChange(data);
      }}
    />
  ) : (
    <Loading />
  );
}
