import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useReducer,
} from 'react';
import { reducer, initialState } from './user-reducer';
import { FirebaseContext } from './FirebaseContext';
import { getMultipleCompaniesFirestore } from '../firebase/companies';
import { arrayToIndexedObject } from '../utils';

export const UserContext = createContext(null);

const UserProvider = (props) => {
  const { children } = props;
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const [user, setUser] = useState();
  const [companiesData, setCompaniesData] = useState();
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  // If user chose dyslexia font in his account settings
  useEffect(() => {
    if (user?.hasDyslexia) {
      document.body.classList.add('dyslexieFont');
    } else {
      document.body.classList.remove('dyslexieFont');
    }

    return () => {
      document.body.classList.remove('dyslexieFont');
    };
  }, [user?.hasDyslexia]);

  async function getWorkerUserData(userAuth) {
    const userSnapshot = await db
      .collection('users')
      .where('authenticationId', '==', userAuth.uid)
      .get();

    let userData = userSnapshot.docs[0]?.data();
    // there is noUserData 1.
    if (!userData) {
      const userSnapshot2 = await db
        .collection('users')
        .where('email', '==', userAuth.email)
        .get();
      userData = userSnapshot2.docs[0]?.data();
    }
    return userData;
  }

  async function getAdminUserData(userAuth) {
    const userSnapshot = await db
      .collection('admin-users')
      .doc(userAuth?.uid)
      .get();

    let userData = userSnapshot.data();

    if (!userData) {
      const userSnapshot2 = await db
        .collection('admin-users')
        .where('email', '==', userAuth?.email)
        .get();
      userData = userSnapshot2.docs[0]?.data();
    }
    return userData;
  }

  function handleLogOut() {
    setUser(null);
  }

  async function handleAuthStateChanged(userAuth) {
    const hasUserLoggedOut = !userAuth;
    if (hasUserLoggedOut) {
      handleLogOut();
      return;
    }

    // Don't show the Steward Portal for admin users
    const adminUser = await getAdminUserData(userAuth);
    const isUserAdmin = Boolean(adminUser);
    if (isUserAdmin) {
      // Show information on the login page
      // that you were logged out because you have logged in as admin
      dispatch({ type: 'adminLoggedIn', value: true });
      handleLogOut();
      return;
    }

    const workerUser = await getWorkerUserData(userAuth);
    const isUserWorker = Boolean(workerUser);
    if (isUserWorker) {
      dispatch({ type: 'workerLoggedIn', value: true });
      setUser({ ...workerUser, userAuth });

      const multipleCompanies = await getMultipleCompaniesFirestore(
        firebase,
        workerUser.memberOfCompanyId,
      );
      const indexedCompanies = arrayToIndexedObject(multipleCompanies, 'id');
      setCompaniesData(indexedCompanies);
    } else {
      console.log('User not found in the database');
      handleLogOut();
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (userAuth) => {
      setIsFetchingUser(true);
      await handleAuthStateChanged(userAuth);
      setIsFetchingUser(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const changeUserData = (data) => {
    setUser(data);
  };

  const refreshUser = async () => {
    const userSnapshot = await db
      .collection('users')
      .where('id', '==', user.id)
      .get();

    const userData = userSnapshot.docs[0]?.data();

    if (userData) {
      setUser({ ...userData });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        isFetchingUser,
        companiesData,
        changeUserData,
        state,
        dispatch,
        refreshUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
