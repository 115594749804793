import React, { useEffect, useState } from 'react';

import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from '../styles/components/MediaCarousel.module.scss';

const MediaCarousel = (props) => {
  const { data } = props;

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          right: 5,
          top: 0,
        }}
      >
        <div
          // className={className}
          style={{
            ...style,
            zIndex: 99,
            backgroundColor: '#00000070',
            color: 'white',
            height: 48,
            width: 48,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderRadius: 10,
            right: 10,
          }}
          onClick={onClick}
        >
          <RightOutlined />
        </div>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          left: 5,
        }}
      >
        <div
          // className={className}
          style={{
            ...style,
            zIndex: 99,
            backgroundColor: '#00000070',
            color: 'white',
            height: 48,
            minWidth: 48,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderRadius: 10,
            left: 10,
          }}
          onClick={onClick}
        >
          <LeftOutlined />
        </div>
      </div>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (data?.media?.length > 0) {
    return (
      <Carousel
        dotPosition="bottom"
        dots
        className={styles.carousel}
        arrows
        {...settings}
      >
        {data.media.map((item) => {
          let mediaType = item.type.slice(0, 5);
          if (mediaType === 'image') {
            return (
              <div key={item.url}>
                <img
                  style={{
                    width: '100%',
                    height: window.innerWidth * 0.6,
                    objectFit: 'contain',
                  }}
                  src={item.url}
                />
              </div>
            );
          } else if (mediaType === 'video') {
            return (
              <div key={item.url}>
                <video
                  // poster="/images/logo-eufa.jpg"
                  poster=""
                  controls
                  preload="none"
                  style={{
                    width: '100%',
                    height: window.innerWidth * 0.6,
                    objectFit: 'contain',
                  }}
                >
                  <source src={item.url} />
                </video>
              </div>
            );
          }
          return null;
        })}
      </Carousel>
    );
  }
  return null;
};

export default MediaCarousel;
