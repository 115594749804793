import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
  const antIcon = (
    <LoadingOutlined
      style={{
        color: '#FCEA25',
        fontSize: 50,
        position: 'absolute',
        top: '42%',
        left: `calc(50vw - 20px)`,
      }}
      spin
    />
  );

  return (
    <div
      style={{
        position: 'fixed',
        height: '100vh',
        backgroundColor: '#000000BD',
        width: '100vw',
        top: 0,
        overflow: 'hidden',
      }}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loading;
