const Avatar = ({ src }) => {
  return (
    <div className="avatar-container">
      <img
        src={src}
        className="avatar-img"
        style={{ width: '40px', height: '40px', borderRadius: '10px' }}
      />
    </div>
  );
};

export default Avatar;
