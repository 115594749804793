import { Button, Col, Drawer, Form, notification, Radio, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Loading } from '.';
import { AdminUserContext, FirebaseContext } from '../../contexts';
import styles from './ProfileDrawer.module.scss';
import { uploadProfilePhotoToStorage } from '../utils/FirebaseStorage';
import Icon from './Icon';
import { InputField, MobileInput, ProfilePhotoField } from './Inputs';
import { validatePassword } from 'admin/utils/misc';

const ProfileDrawer = (props) => {
  const { showProfileDrawer, profileData, closeDrawer } = props;

  const firebase = useContext(FirebaseContext);
  const { refreshUser } = useContext(AdminUserContext);
  const db = firebase.firestore();
  const [url, setUrl] = useState();
  const [image, setImage] = useState();
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [countryCodeType, setCountryCodeType] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [repeatedNewPassword, setRepeatedNewPassword] = useState();

  const [selectedFont, setSelectedFont] = useState('default');

  const [submissionError, setSubmissionError] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (profileData) {
      setUrl(profileData.profilePhoto);
      setFirstname(profileData.firstname);
      setSurname(profileData.surname);
      setEmail(profileData.email);
      setPhoneNumber(profileData.phone);
      setCountryCodeType(profileData.countryCode);
      setSelectedFont(profileData?.hasDyslexia ? 'dyslexie' : 'default');
    } else {
      setUrl('');
      setFirstname('');
      setSurname('');
      setEmail('');
      setPhoneNumber('');
      setCountryCodeType('');
      setSelectedFont('default');
    }
  }, [profileData]);

  const onSave = async () => {
    setSubmissionError('');
    setIsLoading(true);

    const hasDyslexia = selectedFont === 'dyslexie';

    const toPush = {
      firstname,
      surname,
      email,
      hasDyslexia,
    };
    if (!countryCodeType) {
      setIsLoading(false);
      return notification.warning({
        message: 'Error!',
        description: 'Missing required information',
      });
    }

    if (image) {
      const val = { photo: url };
      const snapshots = [val];

      toPush.profilePhoto = await uploadProfilePhotoToStorage(
        snapshots,
        profileData.id,
        firebase,
      );
      setUrl(toPush.profilePhoto);
    }

    toPush.profilePhoto = url || '';

    await db.collection('admin-users').doc(profileData.id).update(toPush);
    await refreshUser();

    setIsLoading(false);
    closeDrawer();
  };

  const changePassword = async () => {
    setIsLoading(true);
    setSubmissionError();
    if (newPassword !== repeatedNewPassword) {
      setSubmissionError('Passwords do not match');
      setIsLoading(false);
    } else if (!validatePassword(newPassword)) {
      setSubmissionError(
        // eslint-disable-next-line max-len
        'Please fill in at least 10 characters, 1 capital letter, 1 lowercase letter, 1 number and 1 special character',
      );
      setIsLoading(false);
    } else {
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(email, currentPassword);
        await firebase.auth().currentUser.updatePassword(newPassword);
        setSubmissionError('Password changed');
        setCurrentPassword();
        setNewPassword();
        setRepeatedNewPassword();
        setIsLoading(false);
        // closeDrawer();
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          setSubmissionError('The current password is invalid');
        } else {
          setSubmissionError(error.message);
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => {
        setIsLoading(false);
        setSubmissionError('');
        closeDrawer();
      }}
      visible={showProfileDrawer}
      width={500}
      destroyOnClose
    >
      <Form onFinish={onSave}>
        <Row gutter={12} className={styles.drawerContainer}>
          <>
            <Button
              className={styles.modalBtnCancel}
              onClick={() => {
                setSubmissionError('');
                closeDrawer();
                setImage();
              }}
            >
              <div style={{ marginRight: 10 }}>Cancel</div>
              <div>
                <Icon name="icon-cancel" size={18} />
              </div>
            </Button>
            <Button className={styles.modalBtnSave} htmlType="submit">
              <div style={{ marginRight: 10 }}>Save</div>
              <div>
                <Icon name="icon-save" size={18} />
              </div>
            </Button>
          </>
          <div style={{ marginBottom: 20 }}>
            <Col span={24}>
              <div className={styles.drawerSubContainer}>
                <Row>
                  <Col span={11}>
                    <div className={styles.imageUploadCol}>
                      <Form.Item>
                        <ProfilePhotoField
                          name="profilephoto"
                          label="Profile photo"
                          url={url}
                          setUrl={setUrl}
                          setImage={setImage}
                          firebase={firebase}
                          checkImageSpecs={true}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Form.Item>
                      <InputField
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        label="Firstname"
                      />
                    </Form.Item>
                    <Form.Item>
                      <InputField
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        label="Surname"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <InputField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        label="Email"
                        required
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <MobileInput
                        countryCode={countryCodeType}
                        phoneValue={phoneNumber}
                        phonePlaceholder="Mobile number"
                        phoneSuffix="icon-phone"
                        fadeSuffix
                        countryOnChange={(e) => setCountryCodeType(e)}
                        phoneOnChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
        </Row>
      </Form>
      <Form onFinish={changePassword}>
        <Row gutter={12} className={styles.drawerContainer}>
          <div style={{ marginBottom: 20 }}>
            <Col span={24}>
              <div className={styles.drawerSubContainer}>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <InputField
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        label="Current Password"
                        required
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <InputField
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        label="New Password"
                        required
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <InputField
                        value={repeatedNewPassword}
                        onChange={(e) => setRepeatedNewPassword(e.target.value)}
                        label="Confirm New Password"
                        required
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{
                        width: '100%',
                        borderRadius: 6,
                        height: 60,
                        fontSize: 18,
                      }}
                    >
                      Change Password
                    </Button>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10,
                      }}
                    >
                      {submissionError}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
        </Row>
      </Form>
      <Row gutter={12} className={styles.drawerContainer}>
        <div style={{ marginBottom: 20 }}>
          <Col span={24}>
            <div className={styles.drawerSubContainer}>
              <div className={styles.sectionTitle}>Font</div>
              <div className={styles.description}>
                Dyslexie is a typeface designed for people with dyslexia for
                better reading and comprehension.
              </div>
              <Radio.Group
                onChange={(e) => setSelectedFont(e.target.value)}
                value={selectedFont}
              >
                <Radio value="default" className={styles.defaultFont}>
                  Default
                </Radio>
                <Radio value="dyslexie" className={styles.dyslexieFont}>
                  Dyslexie
                </Radio>
              </Radio.Group>
            </div>
          </Col>
        </div>
      </Row>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </Drawer>
  );
};

export default ProfileDrawer;