import moment from 'moment';

import Icon from './Icon';
import styles from '../styles/Announcements.module.scss';
import { truncate } from '../utils/misc';

export default function AnnouncementCard({
  firstSelectedColumn,
  onSelectFirstColumn,
  announcement,
}) {
  let styleName =
    announcement.id === firstSelectedColumn?.id
      ? styles.mainContainerSelected
      : styles.mainContainer;
  if (announcement.type === 'global') {
    return (
      <div
        className={styleName}
        onClick={() => onSelectFirstColumn(announcement)}
      >
        <div styles={styles.mainTitle}>General Announcements</div>
        <Icon
          name="icon-down-arrow"
          size={18}
          style={{
            color: '#8C8C8C',
            transform: 'rotate(270deg)',
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        className={styleName}
        onClick={() => onSelectFirstColumn(announcement)}
      >
        <div className={styles.matchContainer} style={{ width: '100%' }}>
          <div className={styles.matchTimeStamp}>
            {moment
              .unix(announcement?.eventsData?.eventDate?.seconds)
              .format('dddd, DD MMMM YYYY hh:mmA')}
          </div>
          <div className={styles.matchDetails}>
            <div className={styles.matchIcon}>
              <Icon
                name="icon-football"
                className="event-card-icon"
                size={24}
              />
            </div>
            <div className={styles.matchName}>
              <div>{truncate(announcement?.eventsData?.eventName, 50)}</div>
              <div className={styles.matchLocation}>
                at {announcement?.eventsData?.eventLocation?.place}
              </div>
            </div>
          </div>
        </div>
        <Icon
          name="icon-down-arrow"
          size={18}
          style={{
            color: '#8C8C8C',
            transform: 'rotate(270deg)',
          }}
        />
      </div>
    );
  }
}
