// NOTE: This is for admin.
const FirebaseStorageMultiUploadFeedback = async (
  firebase,
  snapshots,
  eventId,
  feedbackId,
  name,
) => {
  const storageRef =
    snapshots.type === 'video/mp4'
      ? firebase
          .storage()
          .ref()
          .child(`feedbacks/${eventId}/${feedbackId}/media/${name}.mp4`)
      : firebase
          .storage()
          .ref()
          .child(`feedbacks/${eventId}/${feedbackId}/media/${name}.jpg`);
  const metadata = { contentType: snapshots.type };
  const file = snapshots.url;
  const uploadTask = await storageRef.putString(file, 'data_url', metadata);
  const downloadURL = await uploadTask.ref.getDownloadURL();

  try {
    return downloadURL;
  } catch (e) {
    return e.message;
  }
};

export default FirebaseStorageMultiUploadFeedback;
