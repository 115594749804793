// NOTE: This is for admin.
const uploadProfilePhotoToStorage = async (snapshots, clientId, firebase) => {
  const storageRef = firebase
    .storage()
    .ref()
    .child(`users/${clientId}/images/profile-pic.jpg`);
  const metadata = { contentType: 'image/jpg' };
  const file = snapshots[0].photo;
  const uploadTask = await storageRef.putString(file, 'data_url', metadata);
  const downloadURL = await uploadTask.ref.getDownloadURL();

  try {
    return downloadURL;
  } catch (e) {
    return e.message;
  }
};

// NOTE: This is for  pdf.
const UploadPdfToStorage = async (snapshots, clientId, firebase) => {
  // const fileName = snapshots.originFileObj.name
  const storageRef = firebase.storage().ref().child(clientId);
  const metadata = { contentType: 'application/pdf' };
  // const file = snapshots[0].pdf;
  // const uploadTask = await storageRef.putString(file, 'data_url', metadata);
  const uploadTask = await storageRef.put(snapshots, metadata);
  const downloadURL = await uploadTask.ref.getDownloadURL();
  try {
    return downloadURL;
  } catch (e) {
    return e.message;
  }
};

const uploadPhotoToStorage = async (file, filePath, firebase) => {
  const storageRef = firebase.storage().ref().child(filePath);
  const metadata = { contentType: file.type };
  const uploadTask = await storageRef.put(file, metadata);
  const downloadURL = await uploadTask.ref.getDownloadURL();

  try {
    return downloadURL;
  } catch (e) {
    return e.message;
  }
};

export {
  uploadProfilePhotoToStorage,
  UploadPdfToStorage,
  uploadPhotoToStorage,
};
