import '../styles/globals.scss';
import '../styles/admin-custom.scss';
import '../styles/pwa-custom.scss';
import '../styles/icomoon.css';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { hotjar } from 'react-hotjar';
import {
  AdminDefaultLayout,
  AdminAuthLayout,
  DefaultLayout,
  NonAuthenticatedLayout,
  Default,
} from '../layouts';
import { AdminUserProvider, FirebaseProvider, UserProvider } from '../contexts';
import adminRoutes from '../admin/navigation/routes';
import pwaRoutes from '../pwa/navigation/routes';
import CompanyProvider from 'contexts/CompanyContext';
// import '../i18n';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  // const [isOnline, setIsOnline] = useState(true);

  // useEffect(() => {
  //   if (
  //     typeof window !== 'undefined' &&
  //     'ononline' in window &&
  //     'onoffline' in window
  //   ) {
  //     setIsOnline(window.navigator.onLine);
  //     if (!window.ononline) {
  //       window.addEventListener('online', () => {
  //         setIsOnline(true);
  //       });
  //     }
  //     if (!window.onoffline) {
  //       window.addEventListener('offline', () => {
  //         setIsOnline(false);
  //       });
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (
  //     typeof window !== 'undefined' &&
  //     'serviceWorker' in navigator &&
  //     window.workbox !== undefined &&
  //     isOnline
  //   ) {
  //     // skip index route, because it's already cached under `start-url` caching object
  //     if (router.route !== '/') {
  //       const wb = window.workbox;
  //       wb.active.then((worker) => {
  //         wb.messageSW({ action: 'CACHE_NEW_ROUTE' });
  //       });
  //     }
  //   }
  // }, [isOnline, router.route]);

  useEffect(() => {
    hotjar.initialize(2730325, 6);
  }, []);

  useEffect(() => {
    // console.log('path:', router.pathname);
    if (router.pathname === '/') {
      // router.push('/app', '/app', { locale: 'fr' });
      router.push('/app');
    }
  });

  const getAdminLayout = () => {
    const route = adminRoutes.filter(
      (item) => item.path === router.pathname,
    )[0];

    if (route?.private === false) {
      return (
        <AdminAuthLayout>
          <Component {...pageProps} />
        </AdminAuthLayout>
      );
    }

    return (
      <AdminDefaultLayout>
        <Component {...pageProps} />
      </AdminDefaultLayout>
    );
  };

  const getPWALayout = () => {
    const route = pwaRoutes.filter((item) =>
      router.pathname.includes(item.path),
    )[0];

    if (route?.private === false) {
      return (
        <NonAuthenticatedLayout>
          <Component {...pageProps} />
        </NonAuthenticatedLayout>
      );
    }

    return (
      <DefaultLayout>
        <Component {...pageProps} />
      </DefaultLayout>
    );
  };

  const getCurrentMode = () => {
    const paths = router.pathname.split('/');
    if (paths[1] === 'admin') {
      return 'admin';
    } else if (paths[1] === 'app') {
      return 'pwa';
    }

    return 'other';
  };

  const handleLayout = () => {
    // console.log('layout', getCurrentMode());
    if (getCurrentMode() === 'admin') {
      return (
        <AdminUserProvider>
          <CompanyProvider>{getAdminLayout()}</CompanyProvider>
        </AdminUserProvider>
      );
    } else if (getCurrentMode() === 'pwa') {
      return <UserProvider>{getPWALayout()}</UserProvider>;
    } else {
      return (
        <Default>
          <Component {...pageProps} />
        </Default>
      );
    }

    return null;
  };

  return (
    <>
      <Head>
        {/* <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          // eslint-disable-next-line max-len
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <meta name="description" content="Description" />
        <meta name="keywords" content="Keywords" />
        <title>Aposto</title> */}

        {/* <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/icons/apple-icon.png" />
        <meta name="theme-color" content="#317EFB" /> */}
      </Head>
      <FirebaseProvider>{handleLayout()}</FirebaseProvider>
    </>
  );
}

export default MyApp;