import { formatTimeForLondonTimeZone } from '../admin/utils/misc';

/**
 * This function checks if the image is valid or not before uploading
 * firebase storage
 * @param {String} image - base64 string of an image
 * @param {FirebaseInstance} firebase - firebase instance
 * @returns {Boolean} true | false
 */
export const checkImageSpecification = async (image, firebase) => {
  const checkImageCallable = firebase
    .functions()
    .httpsCallable('checkImageSpecification');

  const uploadImagePayload = {
    template_ex_picture: image,
  };

  const isValid = await checkImageCallable({ payload: uploadImagePayload });
  return isValid.data;
};

/**
 * Retrieves the check-in or check-out time for a user based on the check-in method
 * (QR Code or Biostar system).
 *
 * @param {string} userId - Unique identifier for the user.
 * @param {'CHECKIN' | 'CHECKOUT'} TYPE - Type of time to retrieve, either 'CHECKIN' or 'CHECKOUT'.
 * @param {Object} attendanceDetails - User attendance details
 *  (stored in biostarMapping collection).
 * @param {Object} eventParticipant - Participant event details when QR code check-in is used.
 * @returns {string} - Formatted check-in or check-out time, or '-' if not available.
 */
export function getCheckTime(
  userId,
  TYPE,
  attendanceDetails,
  eventParticipant,
  companyId,
  isQrCodeDisabled,
) {
  if (!companyId || !userId) {
    return '-';
  }
  // Handle companies like Sunderland or BR first
  if (isCompanySunderlandOrBR(companyId)) {
    const biostarCheckTime = getCheckTimeBiostar(
      userId,
      TYPE,
      attendanceDetails,
    );

    // Return Biostar check time if QR codes are disabled or valid check time is found
    if (isQrCodeDisabled || biostarCheckTime !== '-') {
      return biostarCheckTime;
    }
  }

  // If not Sunderland/BR or if Biostar failed, fall back to QR code check time
  return getCheckTimeQrCode(eventParticipant, TYPE);
}

function getCheckTimeQrCode(eventParticipant, type) {
  if (!eventParticipant) return '-';

  let time;
  if (type === 'CHECKIN') {
    time = eventParticipant?.checkInTime;
  } else if (type === 'CHECKOUT') {
    time = eventParticipant?.checkOutTime;
  }

  return time ? formatTimeForLondonTimeZone(time.toDate()) : '-';
}

/**
 * Retrieves check-in or check-out time for a user from attendance details.
 *
 * @param {string} userId - User identifier.
 * @param {'CHECKIN' | 'CHECKOUT'} TYPE - Type of time to retrieve.
 * @param {Object} attendanceDetails - User attendance details (saved in biostarMapping collection).
 * @returns {string} - Formatted time or '-' if not available.
 */
export function getCheckTimeBiostar(userId, TYPE, attendanceDetails) {
  if (attendanceDetails === undefined) {
    return '-';
  }

  let user;
  Object.values(attendanceDetails).forEach((item) => {
    let u = item[TYPE].find((rec) => rec.uid === userId);
    if (u !== undefined) {
      user = u;
    }
  });

  const resp =
    user !== undefined ? formatTimeForLondonTimeZone(user.checkInTime) : '-';
  return resp;
}

/**
 * This function checks if a company is eligible to use Biostar or not
 * @param {String} currentCompanyId
 * @returns
 */
export function isCompanySunderlandOrBR(currentCompanyId) {
  // TEMPORARY -> WE WANT TO SHOW BIOSTAR MAPPING TO SUNDERLAND AFC COMPANY OR BRISTOL ROVERS
  const SUNDERLAND_COMPANY_ID = 'jlyXxxXarOofNAUGJ4mw';
  const BRISTOLROVER_COMPANY_ID = 'swgvPgjqWbO6bY712IMm';

  return (
    currentCompanyId === SUNDERLAND_COMPANY_ID ||
    currentCompanyId === BRISTOLROVER_COMPANY_ID
  );
}

/**
 * This function checks if a company user is allowed to see the Live Check-In Menu Tab
 * @param {String} currentCompanyId
 * @returns
 */
export function isLiveCheckMenuAllowed(currentCompanyId) {
  // TEMPORARY ->
  // WE WANT TO SHOW BIOSTAR MAPPING TO SUNDERLAND AFC COMPANY OR BRISTOL ROVERS OR MCKENZIE ARNOLD
  const ALLOWED_COMPANIES = [
    'jlyXxxXarOofNAUGJ4mw',
    'swgvPgjqWbO6bY712IMm',
    'nurK9GAEIj4ipPq19DTa',
  ];

  return ALLOWED_COMPANIES.includes(currentCompanyId);
}
