import React, { useState, useEffect, useContext } from 'react';
import { Input, Upload, Carousel, Image, Button } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import styles from '../styles/home/Notifications.module.scss';
import { FirebaseContext, UserContext } from '../../contexts';
import { FirebaseStorageMultiUploadFeedback } from '../../admin/utils';
import Icon from './Icon';
import Loading from './Loading';

const { TextArea } = Input;

const Feedback = (props) => {
  const { onPressBack } = props;
  const router = useRouter();
  const firebase = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  const [selectedStarInput, setSelectedStarInput] = useState(0);
  const [contentInput, setContentInput] = useState('');
  const [mediaInput, setMediaInput] = useState([]);
  const [notiData, setNotiData] = useState();
  const [eventData, setEventData] = useState({});
  const [feedbackData, setFeedbackData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [progressMessage, setProgressMessage] = useState();
  const db = firebase.firestore();

  useEffect(async () => {
    async function fetchFeedback(id) {
      let feedbackArray = [];
      let feedbackSnapshots = await db
        .collection('feedback')
        .where('eventId', '==', id)
        .where('userId', '==', user.id)
        .get();

      feedbackSnapshots.docs.forEach((doc) => {
        feedbackArray.push(doc.data());
      });
      if (!feedbackSnapshots.docs[0]?.data()) {
        // console.log('feedbackSnapshots', feedbackSnapshots.docs[0].data());
        // console.log('skipped');
        return null;
      } else {
        return feedbackSnapshots.docs[0].data();
      }
    }
    if (props.eventId) {
      let result = await fetchFeedback(props.eventId);
      // console.log('feedback', result);
      if (result) {
        setFeedbackData(result);
        setDisabled(true);
      }
      // setEventData(result);
    }
    setIsLoading(false);
  }, [props.eventId]);

  useEffect(async () => {
    async function fetchEvent(id) {
      let eventRef = await db.collection('events').doc(id).get();
      return eventRef.data();
    }
    if (props.eventId) {
      let result = await fetchEvent(props.eventId);
      // console.log('res', result);
      setEventData(result);
    }
    setIsLoading(false);
  }, [props.eventId]);

  useEffect(async () => {
    if (feedbackData?.rating) {
      setSelectedStarInput(feedbackData.rating);
    }
    if (feedbackData?.comment) {
      setContentInput(feedbackData.comment);
    }
    if (feedbackData?.media?.length > 0) {
      setMediaInput(feedbackData.media);
    }
  }, [feedbackData]);

  const getDate = (date, formatting) => {
    try {
      return dayjs(date.toDate()).format(
        formatting || 'dddd, D MMMM YYYY [at] h:mm A',
      );
    } catch {
      return '';
    }
  };

  const onUploadInput = async (info) => {
    let fileList = [...info.fileList];
    fileList.forEach(async (file) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.url = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });

    setMediaInput(fileList);
  };

  const onSubmit = async () => {
    setIsSubmitLoading(true);
    let ref = db.collection('feedback').doc();
    let mediaArray = [];
    const toPush = {
      id: ref.id,
      dateCreated: new Date(),
      rating: selectedStarInput,
      companyId: eventData?.companyId ?? '',
      eventId: eventData?.eventId ?? '',
      userId: user?.id ?? '',
      role: user?.role ?? '',
      profilePhoto: user?.profilePhoto ?? '',
      name: `${user?.firstname} ${user?.surname}`,
    };
    // console.log('toPush', toPush);

    if (selectedStarInput <= 0){
      setNotiData('Minimum of one star is required')
      setIsSubmitLoading(false);
      return;
    }

    if (contentInput) {
      toPush.comment = contentInput;
    }
    if (mediaInput.length > 0) {
      for (const [index, item] of mediaInput.entries()) {
        // const val = { photo: item.url };
        const snapshots = item;
        let urlReturn = await FirebaseStorageMultiUploadFeedback(
          firebase,
          snapshots,
          eventData?.eventId,
          ref.id,
          `${ref.id}_${index}`,
        );
        if (urlReturn) {
          mediaArray.push({ url: urlReturn, type: item.type });
        }
      }
      toPush.media = mediaArray;
    }
    await db
      .collection('feedback')
      .doc(toPush.id)
      .set(toPush)
      .then(async () => {
        console.log('done');
        setSelectedStarInput(0);
        setContentInput('');
        setMediaInput([]);
        setIsSubmitLoading(false);
        setProgressMessage(
          'You have already submitted feedback for this event. Thank you!',
        );
        // setNotificationFeedbackView(false);
      });
    onPressBack();
  };

  const renderStarInput = (digit) => {
    let base = [1, 2, 3, 4, 5];
    return (
      <div className={styles.ratingContainer}>
        {base.map((i) => {
          if (i <= digit) {
            return (
              <div onClick={() => (disabled ? {} : setSelectedStarInput(i))}>
                <Icon
                  name="icon-star"
                  size={50}
                  className={styles.icon}
                  style={{ color: '#faad14' }}
                />
              </div>
            );
          } else {
            return (
              <div onClick={() => (disabled ? {} : setSelectedStarInput(i))}>
                <Icon
                  name="icon-star"
                  size={50}
                  className={styles.icon}
                  style={{ color: '#dadada' }}
                />
              </div>
            );
          }
        })}
      </div>
    );
  };

  const NextArrow = (item) => {
    const { className, style, onClick } = item;
    return (
      <div
        // className={className}
        style={{
          ...style,
          zIndex: 99,
          backgroundColor: '#00000070',
          position: 'absolute',
          bottom: 40,
          top: 'unset',
          color: 'white',
          height: 48,
          width: 48,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          borderRadius: 10,
          right: 10,
        }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  };

  const PrevArrow = (item) => {
    const { className, style, onClick } = item;
    return (
      <div
        // className={className}
        style={{
          ...style,
          zIndex: 99,
          backgroundColor: '#00000070',
          position: 'absolute',
          bottom: 40,
          top: 'unset',
          color: 'white',
          height: 48,
          width: 48,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          borderRadius: 10,
          left: 10,
        }}
        onClick={onClick}
      >
        <LeftOutlined />
      </div>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (isLoading) {
    return <Loading />;
  }
  // console.log('router', router.pathname.includes('/fb'));
  return (
    <div>
      <div className={styles.notiFbBg} />
      <div className={styles.notiFeedbackContainer}>
        <div
          className={`${styles.eventContainer} ${styles.shadow}`}
          onClick={() => {
            // setNotificationFeedbackView(!notificationFeedbackView);
            onPressBack();
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div className={styles.time}>{getDate(eventData?.eventDate)}</div>
            {router.pathname.includes('/fb') && (
              <Icon name="icon-close" size={20} style={{ color: 'red' }} />
            )}
          </div>
          <div className={styles.upSubContainer}>
            <div className={styles.subContainer}>
              <Icon name="icon-football" size={24} className={styles.icon} />
              <div>
                <div className={styles.eventTitle}>{eventData?.eventName}</div>
                <div className={styles.location}>{`at ${
                  eventData?.eventLocation?.place
                }${
                  eventData?.eventLocation?.city
                    ? `, ${eventData?.eventLocation?.country}`
                    : ''
                }`}</div>
              </div>
            </div>
          </div>
        </div>
        {notiData && (
          <h3 className={styles.dangerColor}>{notiData}.</h3>
        )}
        <h4>{eventData?.feedbackQuestion}</h4>
        {renderStarInput(selectedStarInput)}
        <div>
          <TextArea
            rows={10}
            // eslint-disable-next-line max-len
            placeholder="(Optional) You can comment here or report something we should know about and share videos and photos of what you have come across on the day."
            value={contentInput}
            onChange={(e) => {
              setContentInput(e.target.value);
            }}
            disabled={disabled}
          />
        </div>
        <div className={styles.carouselContainer}>
          {disabled && (
            <Carousel
              arrows
              {...settings}
              className={styles.announcements}
              adaptiveHeight
            >
              {mediaInput?.map((i) => {
                if (i.type === 'video/mp4') {
                  return (
                    <div className={styles.videoContainer}>
                      <video
                        controls
                        preload="none"
                        className={styles.carouselVideo}
                      >
                        <source src={i.url} />
                      </video>
                    </div>
                  );
                } else {
                  return (
                    <div className={styles.imageContainer}>
                      <Image src={i.url} className={styles.carouselImage} />
                    </div>
                  );
                }
              })}
            </Carousel>
          )}
          {!disabled && (
            <div
              className={styles.bottombuttons}
              // style={{ backgroundColor: 'green' }}
            >
              <Upload
                className={styles.uploadGallery}
                fileList={mediaInput}
                onChange={onUploadInput}
                accept="image/*,.mp4"
              >
                <div className={styles.addphotobutton}>
                  <Icon
                    name="icon-camera"
                    size={24}
                    className={styles.icon}
                    style={{ marginRight: 10 }}
                  />
                  Add photos or videos
                </div>
              </Upload>
              <Button
                className={styles.sendbutton}
                onClick={onSubmit}
                loading={isSubmitLoading}
              >
                <Icon name="icon-send" size={24} className={styles.icon} />
              </Button>
            </div>
          )}
          <div className={styles.finishMessage}>{progressMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
