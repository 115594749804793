import React, { createContext } from 'react';
import app from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

export const FirebaseContext = createContext(null);

export default ({ children }) => {
  if (!app.apps.length) {
    if (process.env.ENVIRONMENT === 'staging') {
      app.initializeApp({
        apiKey: 'AIzaSyDURmwed9x1cbrAbJtTW9NwQFPJilgsox8',
        authDomain: 'aposto-staging.firebaseapp.com',
        projectId: 'aposto-staging',
        storageBucket: 'aposto-staging.appspot.com',
        messagingSenderId: '601424674690',
        appId: '1:601424674690:web:9e2bdc2067bfc80d4bd99b',
        measurementId: 'G-KVH2F1W9KP',
      });
      // firebase.analytics();
    } else if (process.env.ENVIRONMENT === 'production') {
      app.initializeApp({
        apiKey: 'AIzaSyCsJln12-sPrSNXWKeEPaqyeWwgMN0-ehY',
        authDomain: 'aposto-lizard.firebaseapp.com',
        projectId: 'aposto-lizard',
        storageBucket: 'aposto-lizard.appspot.com',
        messagingSenderId: '585907438865',
        appId: '1:585907438865:web:7b4d0e2f5c3c0aee47f8b0',
        measurementId: 'G-13N9YPHKX0',
      });
      // app.analytics();
    }

    app.firestore().settings({
      experimentalAutoDetectLongPolling: true,
    });
    // .enablePersistence()
    // .then(() => {
    //   // const firestore = app.firestore();
    //   // Use Cloud Firestore ...
    // })
    // .catch((err) => {
    //   if (err.code === 'failed-precondition') {
    //     // Multiple tabs open, persistence can only be enabled
    //     // in one tab at a a time.
    //     // ...
    //   } else if (err.code === 'unimplemented') {
    //     // The current browser does not support all of the
    //     // features required to enable persistence
    //     // ...
    //   }
    // });
  }

  return (
    <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
  );
};
