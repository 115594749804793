/**
 * Checks if a module is included in a company package.
 *
 * @param {string} companyPackage - A string representing the company package.
 *  ex. "'Briefing + Training'"
 * @param {string} moduleName - The name of the module to check for inclusion.
 *  ex. "Training"
 * @returns {boolean} - `true` if the module is included in the company package, `false` otherwise.
 */
export function isModuleInCompanyPackage(companyPackage, moduleName) {
  return companyPackage.includes(moduleName);
}
