import { createContext, useContext, useEffect, useState } from 'react';
import { AdminUserContext } from './AdminUserContext';
import { FirebaseContext } from './FirebaseContext';

export const CompanyContext = createContext(null);

const CompanyProvider = (props) => {
  const { children } = props;
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const { company } = useContext(AdminUserContext);
  const [companyRoles, setCompanyRoles] = useState([]);
  const [companyStatuses, setCompanyStatuses] = useState([]);
  const [companyDepartments, setCompanyDepartments] = useState([]);
  const [companyAgencies, setCompanyAgencies] = useState([]);

  function listenCompanyRoles() {
    return db
      .collection('companyRoles')
      .doc(company.id)
      .onSnapshot((doc) => {
        const companyRolesData = doc.data()?.companyRoles ?? [];
        setCompanyRoles(companyRolesData);
      });
  }

  function listenCompanyStatuses() {
    return db
      .collection('companyStatuses')
      .doc(company.id)
      .onSnapshot((doc) => {
        const companyStatusesData = doc.data()?.companyStatuses ?? [];
        setCompanyStatuses(companyStatusesData);
      });
  }

  function listenCompanyDepartments() {
    return db
      .collection('companyDepartments')
      .doc(company.id)
      .onSnapshot((doc) => {
        const companyDepartmentsData = doc.data()?.companyDepartments ?? [];
        setCompanyDepartments(companyDepartmentsData);
      });
  }

  function listenCompanyAgencies() {
    return db
      .collection('staffingAgencies')
      .where('companyId', '==', company.id)
      .onSnapshot((querySnapshot) => {
        const staffingAgenciesData = querySnapshot.docs.map((doc) =>
          doc.data(),
        );
        setCompanyAgencies(staffingAgenciesData);
      });
  }

  useEffect(() => {
    if (!company?.id) {
      return () => {};
    }
    const unsubRoles = listenCompanyRoles();
    const unsubStatuses = listenCompanyStatuses();
    const unsubDepartments = listenCompanyDepartments();
    const unsubAgencies = listenCompanyAgencies();
    return () => {
      unsubRoles();
      unsubStatuses();
      unsubDepartments();
      unsubAgencies();
    };
  }, [company]);

  return (
    <CompanyContext.Provider
      value={{
        companyRoles,
        companyStatuses,
        companyDepartments,
        companyAgencies,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
