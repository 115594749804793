import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import Feedback from './Feedback';
import MediaCarousel from './MediaCarousel';
import styles from '../styles/components/EventNotifications.module.scss';
import { FirebaseContext } from '../../contexts';

const EventNotifications = (props) => {
  const { eventId, eventImage } = props;
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let data = [];
      let snapshot = await db
        .collection('notifications')
        .where('eventId', '==', eventId)
        .get();

      for (let item of snapshot.docs) {
        data.push(item.data());
      }

      data.sort(
        (a, b) =>
          b.dateCreated.toDate().getTime() - a.dateCreated.toDate().getTime(),
      );
      setNotificationData(data);
    }

    fetchData();
  }, [eventId]);

  const NewTag = () => {
    return (
      <div
        style={{ opacity: 0 }}
        className={`${styles.newtag} ${styles.shadow}`}
      >
        NEW
      </div>
    );
  };

  const getDate = (date, formatting) => {
    try {
      return dayjs(date.toDate()).format(
        formatting || 'dddd, D MMMM YYYY [at] h:mm A',
      );
    } catch {
      return '';
    }
  };

  if (notificationData.length === 0) {
    return (
      <div className={styles.errorText}>
        This event currently does not have any notifications.
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        {notificationData?.map((item) => {
          return (
            <div
              key={item.id}
              className={`${styles.maincard} ${styles.shadow}`}
            >
              <div className={`${styles.newtagcont}`}>
                <NewTag />
              </div>
              <div className={`${styles.imagecont} ${styles.shadow}`}>
                <img src={eventImage} />
              </div>
              <div className={styles.titleTop}>
                <div className={styles.eventTitle}>{item?.title}</div>
                <div className={styles.location}>
                  {notificationData?.eventData?.eventLocation ? (
                    `${notificationData?.eventData?.eventLocation.place}${
                      notificationData?.eventData?.eventLocation.city
                        ? `, ${notificationData?.eventData?.eventLocation.city}`
                        : ''
                    }`
                  ) : (
                    <div style={{ height: 20 }} />
                  )}
                </div>
              </div>
              {item?.media?.length > 0 && (
                <div
                  style={{
                    marginRight: -20,
                    marginLeft: -20,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <MediaCarousel data={item} />
                </div>
              )}
              <div className={styles.time}>{getDate(item.dateCreated)}</div>
              <div className={styles.paragraph}>{item?.content}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventNotifications;
