// NOTE: This is for admin.
const FirebaseStorageMultiUpload = async (
  firebase,
  snapshots,
  companyId,
  notificationId,
  name,
) => {
  const storageRef =
    snapshots.type === 'video/mp4'
      ? firebase
          .storage()
          .ref()
          .child(
            `notifications/${companyId}/${notificationId}/media/${name}.mp4`,
          )
      : firebase
          .storage()
          .ref()
          .child(
            `notifications/${companyId}/${notificationId}/media/${name}.jpg`,
          );
  const metadata = { contentType: snapshots.type };
  const file = snapshots.url;
  const uploadTask = await storageRef.putString(file, 'data_url', metadata);
  const downloadURL = await uploadTask.ref.getDownloadURL();

  try {
    return downloadURL;
  } catch (e) {
    return e.message;
  }
};

export default FirebaseStorageMultiUpload;
