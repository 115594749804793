export const reducer = (state, action) => {
  switch (action.type) {
    case 'invalidLogin':
      return {
        ...state,
        invalidLogin: action.value,
      };

    case 'headerTitle':
      return {
        ...state,
        headerTitle: action.value,
      };

    case 'activePage':
      return {
        ...state,
        activePage: action.value,
      };

    case 'activeTab':
      return {
        ...state,
        activeTab: action.value,
      };
    case 'headerBackBtn':
      return {
        ...state,
        headerBackBtn: action.value,
      };
    case 'workerLoggedIn':
      return {
        ...state,
        workerLoggedIn: action.value,
      };
    case 'adminLoggedIn':
      return {
        ...state,
        adminLoggedIn: action.value,
      };

    default:
      return state;
  }
};

export const initialState = {
  invalidLogin: false,
  headerTitle: '',
  activePage: '',
  activeTab: '',
  headerBackBtn: false,
};
