import React, { useEffect, useState } from 'react';
import splash from '../../../public/images/email/aposto-logo-black.png'
import Image from 'next/image';

const Splash = (props) => {

  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);


  useEffect(() => {
    if (typeof window !== "undefined") {
      // browser code
      setScreenWidth(window?.innerWidth);
      setScreenHeight(window?.innerHeight)
    }
  }, [])


  return (
    <div style={{ backgroundColor: '#FCEA25', justifyContent: 'center', alignItems: 'center', display: 'flex', minHeight: screenHeight, minWidth: screenWidth }}>
      <Image src={splash} />
    </div>
  );
};

export default Splash;