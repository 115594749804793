import { Button, Modal, Table } from 'antd';
import styles from '../styles/Modal.module.scss';

const ViewImportStaffModal = (props) => {
  const {
    visible,
    values,
    setVisible,
    onOk,
    onCancel,
    loading,
    workersWithErrors,
    csvErrors,
    showErrorModal,
    setShowErrorModal,
  } = props;

  const renderColumns = () => {
    return [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email?.localeCompare(b.email),
      },
      {
        title: 'First Name',
        dataIndex: 'firstname',
        key: 'firstname',
      },
      {
        title: 'Surname',
        dataIndex: 'surname',
        key: 'surname',
      },
      {
        title: 'Country Code',
        dataIndex: 'countryCode',
        key: 'countryCode',
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Departments',
        dataIndex: 'departments',
        key: 'departments',
      },
      {
        title: 'Agencies',
        dataIndex: 'agencies',
        key: 'agencies',
      },
      {
        title: 'payroll ID',
        dataIndex: 'payroll',
        key: 'payroll',
      },
    ];
  };

  return (
    <Modal
      visible={visible}
      width="90%"
      className={styles.newItemModal}
      footer={null}
      closable={false}
      onCancel={() => setVisible(false)}
      destroyOnClose
    >
      <div className={styles.modalInputContainer}>
        <div className={styles.actionButtonContainer}>
          <Button
            className={styles.topBtnSave}
            onClick={() => onOk()}
            disabled={Boolean(csvErrors.length) || loading}
            loading={loading}
          >
            <div style={{ marginRight: 10 }}>OK</div>
          </Button>
          <Button className={styles.topBtnCancel} onClick={() => onCancel()}>
            <div style={{ marginRight: 10 }}>Cancel</div>
          </Button>
        </div>
        <Table
          className={styles.csvTable}
          columns={renderColumns()}
          dataSource={values}
          rowKey={(item) => item.id}
          // show row in red if it is causing an error
          rowClassName={(_record, index) => {
            if (workersWithErrors.includes(index)) {
              return styles.errorRow;
            }
            return null;
          }}
        />
      </div>
      <Modal
        visible={showErrorModal}
        width="80%"
        className={styles.newItemModal}
        footer={null}
        onCancel={() => setShowErrorModal(false)}
        destroyOnClose
      >
        <div className={styles.errorModalContent}>
          <div className={styles.errorModalTitle}>
            Errors when validating data:
          </div>
          <ul>
            {csvErrors.map((errorMessage) => (
              <li key={errorMessage}>{errorMessage}</li>
            ))}
          </ul>
        </div>
      </Modal>
    </Modal>
  );
};

export default ViewImportStaffModal;
