export async function getCompanyFirestore(firebase, companyId) {
  const db = firebase.firestore();
  const companySnapshot = await db.collection('companies').doc(companyId).get();
  if (companySnapshot.exists) {
    return companySnapshot.data();
  } else {
    console.error(`Could not find company with id ${companyId}`);
    return undefined;
  }
}

export async function getMultipleCompaniesFirestore(
  firebase,
  arrayOfCompaniesIds,
) {
  const companyPromises = arrayOfCompaniesIds.map((companyId) =>
    getCompanyFirestore(firebase, companyId),
  );
  const companies = await Promise.all(companyPromises);
  const filteredCompanies = companies.filter(
    (user) => user !== null && user !== undefined,
  );
  return filteredCompanies;
}
