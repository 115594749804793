import React, { useState, useEffect } from 'react';
import { Popconfirm } from 'antd';
import styles from '../styles/components/StaffingAgencyButton.module.scss';
import Icon from './Icon';

const StaffingAgencyButton = (props) => {
  const {
    headerTitle,
    title,
    onClick,
    selected,
    onDeleteAgency,
    disabled,
  } = props;

  return (
    <div
      onClick={onClick}
      className={selected ? styles.containerSelected : styles.container}
    >
      <div className={styles.headerTitle}>{headerTitle}</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name="icon-avatar" size={15} style={{ color: '#1890FF' }} />
        <div className={styles.title}>{title}</div>
      </div>

      <Popconfirm
        placement="topRight"
        title="Are you sure you want to delete agency?"
        onConfirm={onDeleteAgency}
        okText="Yes"
        cancelText="No"
        disabled={disabled}
      >
        <div onClick={(event) => event.stopPropagation()}>
          <Icon
            name="icon-trash"
            size={16}
            className={styles.deleteIcon}
            style={{ color: disabled ? '#BFBFBF' : '#FB4953' }}
          />
        </div>
      </Popconfirm>

      <Icon
        name="icon-down-arrow"
        size={12}
        className={styles.rightArrowIcon}
      />
    </div>
  );
};

export default StaffingAgencyButton;
