import React from 'react';
import styles from '../styles/InactiveScreen.module.scss';

const InactiveScreen = ({ text }) => {

  return (
    <div className={styles.title}>
      The company has been deactivated. Please contact admin for more
      information
    </div>
  );
};

export default InactiveScreen;
