/**
 * Checks if a given ID exists in an array of objects representing Ant Design Select options.
 *
 * @param {string|number} id - The ID to search for.
 * @param {Array} arrayOfObjects - The array of objects representing Ant Design Select options.
 * @returns {boolean} - True if the ID exists in the array of objects, false otherwise.
 */
function isIdInOptions(id, arrayOfObjects) {
  return arrayOfObjects.some((object) => object.value === id);
}

/**
 * Converts an array of objects into an array of options suitable for Ant Design's Select component.
 *
 * @param {Array} array - The input array containing objects.
 * @param {string} [nameKey='name'] - The key of the object property
 * to be used as the label for the options.
 * @param {string} [idKey='id'] - The key of the object property
 *  to be used as the value for the options.
 * @returns {Array} - An array of options with 'label' and 'value' properties.
 */
function getAntdSelectOptions(array, nameKey = 'name', idKey = 'id') {
  return array.map((element) => ({
    label: element[nameKey],
    value: element[idKey],
  }));
}

export { isIdInOptions, getAntdSelectOptions };
