import React, { useEffect, useContext } from 'react';
import { Layout } from 'antd';
import { useRouter } from 'next/router';
import { UserContext } from '../contexts';
import PWAHead from './PWAHead';

const Default = ({ children }) => {
  const { Content } = Layout;

  // Don't use this way. Reference purposes
  // useEffect(() => {
  //   if (user && !isFetchingUser) {
  //     router.push('/app');
  //   }
  // }, [user, isFetchingUser]);

  return <Content>{children}</Content>;
};

export default Default;
