export default class CustomUploadAdapter {
  constructor(loader, firebase, docId, storageName) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.firebase = firebase;
    this.docId = docId;
    this.storageName = storageName;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const storage = this.firebase
            ?.storage()
            .ref(`${this.storageName}/${this.docId}/`);
          const uploadTask = storage
            .child(`${Math.round(new Date().getTime() / 1000)}_${file.name}`)
            .put(file);
          uploadTask.on(
            this.firebase?.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
              // Get task progress, including the number of bytes uploaded and the total
              // number of bytes to be uploaded
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case this?.firebase?.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case this?.firebase?.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
                default:
                  break;
              }
            },
            function (error) {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              // eslint-disable-next-line default-case
              switch (error.code) {
                case 'storage/unauthorized':
                  reject(" User doesn't have permission to access the object");
                  break;

                case 'storage/canceled':
                  reject('User canceled the upload');
                  break;

                case 'storage/unknown':
                  reject(
                    'Unknown error occurred, inspect error.serverResponse',
                  );
                  break;

                default:
                  break;
              }
            },
            function () {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  // console.log("File available at", downloadURL);
                  resolve({
                    default: downloadURL,
                  });
                });
            },
          );
        }),
    );
  }
}
