export default [
  {
    path: '/admin/login',
    private: false,
  },
  {
    path: '/admin/registration',
    private: false,
  },
  {
    path: '/admin/forgot-password',
    private: false,
  },
  {
    path: '/admin/reset-password',
    private: false,
  },
  // {
  //   path: '/404',
  //   private: false,
  // },
  // {
  //   path: '/admin',
  //   private: true,
  // },
  // {
  //   path: '/admin/staff',
  //   private: true,
  // },
  // {
  //   path: '/admin/event-overview',
  //   private: true,
  // },
  // {
  //   path: '/admin/event-new',
  //   private: true,
  // },
  // {
  //   path: '/admin/learning-module/overview',
  //   private: true,
  // },
];
