import { ROLES } from '../constants';

export const isAuthorized = (role, section) => {
  if (role === ROLES.companyAdmin) {
    if (section === 'switchCompany') {
      return false;
    }
    return true;
  }

  if (role === ROLES.eventManager) {
    if (section === 'companySettings') {
      return false;
    }
  }

  if (role === ROLES.agentRepresentative) {
    if (section === 'dashboard') {
      return false;
    }
    if (section === 'createEvent') {
      return false;
    }
    if (section === 'eventDetails') {
      return false;
    }
    if (section === 'companySettings') {
      return false;
    }
    if (section === 'announcements') {
      return false;
    }
    if (section === 'learningModules') {
      return false;
    }
    if (section === 'agencyColumn') {
      return false;
    }
  }

  return true;
};
