import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { UserContext } from '../contexts';
import { Splash } from '../pwa/components';
import PWAHead from './PWAHead';

const DefaultLayout = ({ children }) => {
  const router = useRouter();
  const { user, isFetchingUser } = useContext(UserContext);
  const { eventId } = router.query;

  useEffect(() => {
    if (!user && !isFetchingUser) {
      if (eventId) {
        localStorage.setItem('eventId', eventId);
      }
      router.push('/app/login');
    }
  }, [user, isFetchingUser]);

  if (user && !isFetchingUser) {
    const eventIdFromFeedback = localStorage.getItem('eventId');
    if (eventIdFromFeedback) {
      router.push(`/app/fb/${eventIdFromFeedback}`);
    }

    return (
      <div style={{ backgroundColor: 'white' }}>
        <PWAHead />
        {children}
      </div>
    );
  }

  return <Splash />;
};

export default DefaultLayout;
