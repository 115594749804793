import React, { useContext, useEffect } from 'react';
import { Layout } from 'antd';
import { useRouter } from 'next/router';
import {
  Header,
  ApostoAdminHeader,
  SideNavigation,
  Loading,
  InactiveScreen,
} from '../admin/components';
import { AdminUserContext, FirebaseContext } from '../contexts';
import { ROLES } from '../admin/constants';

const { Content } = Layout;

const AdminDefaultLayout = ({ children }) => {
  const router = useRouter();
  const { user, isFetchingUser, company, verified } = useContext(
    AdminUserContext,
  );
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    if (!user && !isFetchingUser) {
      router.push('/admin/login');
    }
  }, [user, isFetchingUser]);

  // useEffect(() => {
  //   console.log('verified layout', verified);
  //   const timer = setTimeout(() => {
  //     if (!verified) {
  //       router.push('/admin/login');
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // }, [router.asPath]);

  useEffect(() => {
    // check role permission
    if (user && !isFetchingUser) {
      if (
        (user.role === ROLES.apostoAdmin &&
          !router.pathname.includes('/admin')) ||
        (user.role !== ROLES.apostoAdmin &&
          router.pathname.includes('admin/management'))
      ) {
        firebase.auth().signOut();
      }
    }
  }, [user, router.route, isFetchingUser]);

  if (user?.role === ROLES.apostoAdmin) {
    return (
      <Layout className="default-layout-container">
        <ApostoAdminHeader />
        {children}
      </Layout>
    );
  } else if (company?.inactive) {
    return (
      <Layout className="default-layout-container">
        <Header />
        <Layout>
          <SideNavigation disabled />
          <Content
            style={{
              minHeight: 'calc(100vh - 78px - 74px)',
            }}
          >
            <InactiveScreen />
          </Content>
        </Layout>
      </Layout>
    );
  } else if (user && !isFetchingUser) {
    return (
      <Layout className="default-layout-container">
        <Header />
        <Layout>
          <SideNavigation />
          <Content
            style={{
              minHeight: 'calc(100vh - 78px - 74px)',
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    );
  }

  return <Loading />;
};

export default AdminDefaultLayout;