// eslint-disable-next-line no-unused-vars
import moment, { Moment } from 'moment';

/**
 * Combines a moment.js selected date and a moment.js selected time into a single moment.js object.
 *
 * @param {Moment} momentDate - The moment.js object with selected date.
 * @param {Moment} momentTime - The moment.js object with selected time.
 * @returns {Moment} The combined moment.js object representing the combined date and time,
 *  or null if either `momentDate` or `momentTime` is falsy.
 */
function combineMomentDateAndTime(momentDate, momentTime) {
  if (!momentDate || !momentTime) {
    return null;
  }

  const time = momentTime.format('HH:mm:ss');
  const date = momentDate.format('YYYY-MM-DD');
  const combinedMomentDateAndTime = moment(`${date}T${time}`);
  return combinedMomentDateAndTime;
}

export { combineMomentDateAndTime };
