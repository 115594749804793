import React, { useContext } from 'react';
import { Layout, Tooltip } from 'antd';
import { useRouter } from 'next/router';
import Icon from './Icon';
import { AdminUserContext } from '../../contexts';
import styles from '../styles/SideNavigation.module.scss';

const { Sider } = Layout;

const SideNavigation = ({ disabled }) => {
  const router = useRouter();
  const { state } = useContext(AdminUserContext);

  let siderConfig = [
    {
      pathname: '/admin',
      icon: 'icon-speedometer',
      activePage: '',
      text: 'Dashboard',
    },
    {
      pathname: '/admin/event-overview',
      icon: 'icon-calendar',
      activePage: 'event',
      text: 'Events',
    },

    {
      pathname: '/admin/staff',
      icon: 'icon-avatar',
      activePage: 'staff',
      text: 'All Staff',
    },
    {
      pathname: '/admin/learning-module/overview',
      icon: 'icon-healthcare-and-medical',
      activePage: 'modules',
      text: 'Training Modules',
    },
    {
      pathname: '/admin/module-assignment',
      icon: 'icon-assignment',
      activePage: 'assignment',
      text: 'Assign Training Modules',
    },
    {
      pathname: '/admin/announcements',
      icon: 'icon-megaphone',
      activePage: 'announcements',
      text: 'Announcements',
    },
  ];

  if (disabled) {
    siderConfig = ['', '', '', '', ''];
  }
  return (
    <Sider className={styles.sider}>
      <div className={styles.siderContent}>
        <div className={styles.menuList}>
          {siderConfig.map(({ pathname, icon, activePage, text }) => (
            <div key={pathname} className={styles.iconContainer}>
              <Tooltip placement="right" title={text}>
                <div
                  className={
                    state?.activePage !== activePage
                      ? styles.menuBtn
                      : styles.menuBtnActive
                  }
                  onClick={() => router.push(pathname)}
                >
                  <Icon name={icon} className="menu-icon" size={22} />
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </Sider>
  );
};

export default SideNavigation;